.tab-panel {
    font-size: $accordion-font-size;

    .tab-content {
        @extend %text-hyphens;
    }

    @include media-breakpoint-down(lg) {
        &__content {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        @include make-row();

        .accordion {
            display: none !important;
        }

        &__nav,
        &__content {
            @include make-col-ready;
            @include make-col(6);
        }

        &__content {
            margin-top: 20px;
        }
    }

    @include media-breakpoint-up(xl) {
        &__nav {
            @include make-col(5);
            margin-left: auto;
            margin-right: auto;
        }
    }
}
