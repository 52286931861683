@use 'sass:math';

/* Form */
select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
    @extend .form-control;

    &.error {
        @extend .is-invalid;
    }
}

select {
    @extend .form-select;
}

.formbody {
    .widget {
        margin-bottom: calc($vr / 2);

        > label,
        > fieldset > legend {
            display: block;
            margin-bottom: 0.2rem;
            font-weight: bold;

            span.mandatory {
                margin-left: 2px;
                color: $danger;
            }
        }
    }

    p.error {
        margin-bottom: 0.2rem;
        color: $danger;
        font-weight: bold;
    }

    .radio_container,
    .checkbox_container {
        margin: 0;
        padding: 0;
        border: 0;

        > span {
            display: block;
        }
    }

    .captcha {
        width: 75px;
        margin-right: 8px;
        text-align: center;
    }

    .captcha_text {
        font-weight: bold;
    }

    .submit {
        @extend .btn;
        @extend .btn-primary;
    }
}

/* Ajax form */
.ce_form form {
    transition: opacity $transition-time;

    &.ajax-loading {
        cursor: progress;
        opacity: 0.5;

        .formbody {
            pointer-events: none;
        }
    }

    .ajaxconfirm {
        @extend .alert;
        @extend .alert-success;

        *:last-child {
            margin-bottom: 0;
        }
    }
}

/* Checkbox / Radio field */
.radio_container,
.checkbox_container {
    > span {
        @extend .form-check;

        input {
            @extend .form-check-input;
        }

        label {
            @extend .form-check-label;
        }
    }
}
