.intro-text-wrapper {
    color: $white;
    padding-top: calc(var(--header-padding-y) + var(--header-height));
    padding-bottom: calc(var(--header-padding-y) + var(--header-height));
    background-color: #ff5f3d;
    margin-bottom: 0;
    min-height: var(--banner-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    will-change: transform;
}

.intro-text {
    hyphens: auto;
    hyphenate-limit-chars: 8 3 4;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: clamp(100px, 12vw, 9rem);
    line-height: 1;
    font-weight: $font-weight-medium;

    > *:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        font-weight: $font-weight-semibold;
        line-height: calc(150 / 134);
        max-width: 7.9em;
    }

    @include media-breakpoint-up(xl) {
        font-size: 9rem;
    }
}
