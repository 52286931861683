@charset "UTF-8";
/* Mixins */
/* Mixins */
/* Vertical align */
/* Sticky footer */
/* Link variant */
/* Add icon */
/* Px to rem */
/* Dropdown navigation */
/* Add responsive properties */
/* Make row and columns */
/* Set gutter-y variable */
@keyframes linkUnderlineVisibleAnimate {
  from {
    background-size: 0 var(--underline-size);
  }
  to {
    background-size: 100% var(--underline-size);
  }
}
/* Add section bg */
/*
* SCSS CSS triangle mixin, create any kind of triangles with ease
*/
/* Variables */
/* IcoMoon fonts */
/* Vertical rhythm */
/* Mobile menu */
/* Desktop dropdown navigation */
/* Vendor – Bootstrap */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #163c60;
  --bs-secondary: #70e788;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 22, 60, 96;
  --bs-secondary-rgb: 112, 231, 136;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: rgb(8.8, 24, 38.4);
  --bs-secondary-text-emphasis: rgb(44.8, 92.4, 54.4);
  --bs-success-text-emphasis: rgb(10, 54, 33.6);
  --bs-info-text-emphasis: rgb(5.2, 80.8, 96);
  --bs-warning-text-emphasis: rgb(102, 77.2, 2.8);
  --bs-danger-text-emphasis: rgb(88, 21.2, 27.6);
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: rgb(208.4, 216, 223.2);
  --bs-secondary-bg-subtle: rgb(226.4, 250.2, 231.2);
  --bs-success-bg-subtle: rgb(209, 231, 220.8);
  --bs-info-bg-subtle: rgb(206.6, 244.4, 252);
  --bs-warning-bg-subtle: rgb(255, 242.6, 205.4);
  --bs-danger-bg-subtle: rgb(248, 214.6, 217.8);
  --bs-light-bg-subtle: rgb(251.5, 252, 252.5);
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: rgb(161.8, 177, 191.4);
  --bs-secondary-border-subtle: rgb(197.8, 245.4, 207.4);
  --bs-success-border-subtle: rgb(163, 207, 186.6);
  --bs-info-border-subtle: rgb(158.2, 233.8, 249);
  --bs-warning-border-subtle: rgb(255, 230.2, 155.8);
  --bs-danger-border-subtle: rgb(241, 174.2, 180.6);
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Montserrat Variable", Open Sans, sans-serif;
  --bs-body-font-size: 0.9999999992rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6666666667;
  --bs-body-color: #163c60;
  --bs-body-color-rgb: 22, 60, 96;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(22, 60, 96, 0.75);
  --bs-secondary-color-rgb: 22, 60, 96;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(22, 60, 96, 0.5);
  --bs-tertiary-color-rgb: 22, 60, 96;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: var(--headings-color, #163c60);
  --bs-link-color: #163c60;
  --bs-link-color-rgb: 22, 60, 96;
  --bs-link-decoration: none;
  --bs-link-hover-color: rgb(17.6, 48, 76.8);
  --bs-link-hover-color-rgb: 18, 48, 77;
  --bs-code-color: #d63384;
  --bs-highlight-color: #163c60;
  --bs-highlight-bg: rgb(255, 242.6, 205.4);
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(22, 60, 96, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}
@media (min-width: 1200px) {
  :root,
  [data-bs-theme=light] {
    --bs-body-font-size: 1rem;
  }
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: rgb(42.5, 47.5, 52.5);
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: rgb(115.2, 138, 159.6);
  --bs-secondary-text-emphasis: rgb(169.2, 240.6, 183.6);
  --bs-success-text-emphasis: rgb(117, 183, 152.4);
  --bs-info-text-emphasis: rgb(109.8, 223.2, 246);
  --bs-warning-text-emphasis: rgb(255, 217.8, 106.2);
  --bs-danger-text-emphasis: rgb(234, 133.8, 143.4);
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: rgb(4.4, 12, 19.2);
  --bs-secondary-bg-subtle: rgb(22.4, 46.2, 27.2);
  --bs-success-bg-subtle: rgb(5, 27, 16.8);
  --bs-info-bg-subtle: rgb(2.6, 40.4, 48);
  --bs-warning-bg-subtle: rgb(51, 38.6, 1.4);
  --bs-danger-bg-subtle: rgb(44, 10.6, 13.8);
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: rgb(13.2, 36, 57.6);
  --bs-secondary-border-subtle: rgb(67.2, 138.6, 81.6);
  --bs-success-border-subtle: rgb(15, 81, 50.4);
  --bs-info-border-subtle: rgb(7.8, 121.2, 144);
  --bs-warning-border-subtle: rgb(153, 115.8, 4.2);
  --bs-danger-border-subtle: rgb(132, 31.8, 41.4);
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: rgb(115.2, 138, 159.6);
  --bs-link-hover-color: rgb(143.16, 161.4, 178.68);
  --bs-link-color-rgb: 115, 138, 160;
  --bs-link-hover-color-rgb: 143, 161, 179;
  --bs-code-color: rgb(230.4, 132.6, 181.2);
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: rgb(102, 77.2, 2.8);
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: rgb(117, 183, 152.4);
  --bs-form-valid-border-color: rgb(117, 183, 152.4);
  --bs-form-invalid-color: rgb(234, 133.8, 143.4);
  --bs-form-invalid-border-color: rgb(234, 133.8, 143.4);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: var(--headings-font-weight, 900);
  line-height: 1.25;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.4361111102rem + 2.5125000013vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.1111111111rem;
  }
}

h2, .h2 {
  font-size: calc(1.4361111102rem + 2.5125000013vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 3.1111111111rem;
  }
}

h3, .h3 {
  font-size: calc(1.3027777769rem + 0.7125000013vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.7777777778rem;
  }
}

h4, .h4 {
  font-size: 1.2222222222rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.8888888889rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.274999999rem + 0.3374999997vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.249999999rem;
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.25rem;
  }
}

.display-1 {
  font-size: calc(1.6249999987rem + 5.062499996vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.5749999987rem + 4.3874999965vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.5249999988rem + 3.712499997vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.4749999988rem + 3.0374999976vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.4249999989rem + 2.3624999981vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.3749999989rem + 1.6874999987vw);
  font-weight: 300;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.249999999rem;
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.25rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.container,
.container-fluid,
.container-xxl,
.section--simple-content,
.header-container,
.layout-footer__inside,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: var(--container-padding-x);
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .section--simple-content, .header-container,
  .layout-footer__inside, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1355px;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(18, 48, 77, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(18, 48, 77, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(141, 236, 160, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(141, 236, 160, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-43 {
  --bs-aspect-ratio: 75%;
}

.ratio-3x2 {
  --bs-aspect-ratio: 66.6666666667%;
}

.ratio-32 {
  --bs-aspect-ratio: 66.6666666667%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-169 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-16x10 {
  --bs-aspect-ratio: 62.5%;
}

.ratio-1610 {
  --bs-aspect-ratio: 62.5%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.ratio-219 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.table, .ce_table table,
.ce_text table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > *, .ce_table table > :not(caption) > * > *,
.ce_text table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody, .ce_table table > tbody,
.ce_text table > tbody {
  vertical-align: inherit;
}
.table > thead, .ce_table table > thead,
.ce_text table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > *, .ce_table table > tbody > tr:hover > *,
.ce_text table > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(208.4, 216, 223.2);
  --bs-table-border-color: rgb(166.72, 172.8, 178.56);
  --bs-table-striped-bg: rgb(197.98, 205.2, 212.04);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(187.56, 194.4, 200.88);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(192.77, 199.8, 206.46);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(226.4, 250.2, 231.2);
  --bs-table-border-color: rgb(181.12, 200.16, 184.96);
  --bs-table-striped-bg: rgb(215.08, 237.69, 219.64);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(203.76, 225.18, 208.08);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(209.42, 231.435, 213.86);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: rgb(209, 231, 220.8);
  --bs-table-border-color: rgb(167.2, 184.8, 176.64);
  --bs-table-striped-bg: rgb(198.55, 219.45, 209.76);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(188.1, 207.9, 198.72);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(193.325, 213.675, 204.24);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: rgb(206.6, 244.4, 252);
  --bs-table-border-color: rgb(165.28, 195.52, 201.6);
  --bs-table-striped-bg: rgb(196.27, 232.18, 239.4);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(185.94, 219.96, 226.8);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.105, 226.07, 233.1);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: rgb(255, 242.6, 205.4);
  --bs-table-border-color: rgb(204, 194.08, 164.32);
  --bs-table-striped-bg: rgb(242.25, 230.47, 195.13);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(229.5, 218.34, 184.86);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(235.875, 224.405, 189.995);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: rgb(248, 214.6, 217.8);
  --bs-table-border-color: rgb(198.4, 171.68, 174.24);
  --bs-table-striped-bg: rgb(235.6, 203.87, 206.91);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 193.14, 196.02);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 198.505, 201.465);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: rgb(198.4, 199.2, 200);
  --bs-table-striped-bg: rgb(235.6, 236.55, 237.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 224.1, 225);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 230.325, 231.25);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: rgb(77.4, 80.6, 83.8);
  --bs-table-striped-bg: rgb(44.1, 47.9, 51.7);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgb(55.2, 58.8, 62.4);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgb(49.65, 53.35, 57.05);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.btn, .link-more-btn a, .formbody .submit {
  --bs-btn-padding-x: 1.6em;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: inherit;
  --bs-btn-font-size: 0.8888888882rem;
  --bs-btn-font-weight: 800;
  --bs-btn-line-height: 1.6666666667;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 3px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .btn, .link-more-btn a, .formbody .submit {
    --bs-btn-font-size: 0.8888888889rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn, .link-more-btn a, .formbody .submit {
    transition: none;
  }
}
.btn:hover, .link-more-btn a:hover, .formbody .submit:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover, .link-more-btn .btn-check + a:hover, .formbody .btn-check + .submit:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible, .link-more-btn a:focus-visible, .formbody .submit:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn, .link-more-btn .btn-check:focus-visible + a, .formbody .btn-check:focus-visible + .submit {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .link-more-btn .btn-check:checked + a, .formbody .btn-check:checked + .submit, :not(.btn-check) + .btn:active, .link-more-btn :not(.btn-check) + a:active, .formbody :not(.btn-check) + .submit:active, .btn:first-child:active, .link-more-btn a:first-child:active, .formbody .submit:first-child:active, .btn.active, .link-more-btn a.active, .formbody .active.submit, .btn.show, .link-more-btn a.show, .formbody .show.submit {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, .link-more-btn .btn-check:checked + a:focus-visible, .formbody .btn-check:checked + .submit:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .link-more-btn :not(.btn-check) + a:active:focus-visible, .formbody :not(.btn-check) + .submit:active:focus-visible, .btn:first-child:active:focus-visible, .link-more-btn a:first-child:active:focus-visible, .formbody .submit:first-child:active:focus-visible, .btn.active:focus-visible, .link-more-btn a.active:focus-visible, .formbody .active.submit:focus-visible, .btn.show:focus-visible, .link-more-btn a.show:focus-visible, .formbody .show.submit:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn, .link-more-btn .btn-check:checked:focus-visible + a, .formbody .btn-check:checked:focus-visible + .submit {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .link-more-btn a:disabled, .formbody .submit:disabled, .btn.disabled, .link-more-btn a.disabled, .formbody .disabled.submit, fieldset:disabled .btn, fieldset:disabled .link-more-btn a, .link-more-btn fieldset:disabled a, fieldset:disabled .formbody .submit, .formbody fieldset:disabled .submit {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary, .formbody .submit {
  --bs-btn-color: #fff;
  --bs-btn-bg: #163c60;
  --bs-btn-border-color: #163c60;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(18.7, 51, 81.6);
  --bs-btn-hover-border-color: rgb(17.6, 48, 76.8);
  --bs-btn-focus-shadow-rgb: 57, 89, 120;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(17.6, 48, 76.8);
  --bs-btn-active-border-color: rgb(16.5, 45, 72);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #163c60;
  --bs-btn-disabled-border-color: #163c60;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #70e788;
  --bs-btn-border-color: #70e788;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(133.45, 234.6, 153.85);
  --bs-btn-hover-border-color: rgb(126.3, 233.4, 147.9);
  --bs-btn-focus-shadow-rgb: 95, 196, 116;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(140.6, 235.8, 159.8);
  --bs-btn-active-border-color: rgb(126.3, 233.4, 147.9);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #70e788;
  --bs-btn-disabled-border-color: #70e788;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(21.25, 114.75, 71.4);
  --bs-btn-hover-border-color: rgb(20, 108, 67.2);
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(20, 108, 67.2);
  --bs-btn-active-border-color: rgb(18.75, 101.25, 63);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(49.3, 209.95, 242.25);
  --bs-btn-hover-border-color: rgb(37.2, 207.3, 241.5);
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(61.4, 212.6, 243);
  --bs-btn-active-border-color: rgb(37.2, 207.3, 241.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(255, 202.3, 44.2);
  --bs-btn-hover-border-color: rgb(255, 199.2, 31.8);
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(255, 205.4, 56.6);
  --bs-btn-active-border-color: rgb(255, 199.2, 31.8);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(187, 45.05, 58.65);
  --bs-btn-hover-border-color: rgb(176, 42.4, 55.2);
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(176, 42.4, 55.2);
  --bs-btn-active-border-color: rgb(165, 39.75, 51.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(210.8, 211.65, 212.5);
  --bs-btn-hover-border-color: rgb(198.4, 199.2, 200);
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(198.4, 199.2, 200);
  --bs-btn-active-border-color: rgb(186, 186.75, 187.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(66.3, 69.7, 73.1);
  --bs-btn-hover-border-color: rgb(55.2, 58.8, 62.4);
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(77.4, 80.6, 83.8);
  --bs-btn-active-border-color: rgb(55.2, 58.8, 62.4);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary, .link-more-btn a {
  --bs-btn-color: #163c60;
  --bs-btn-border-color: #163c60;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #163c60;
  --bs-btn-hover-border-color: #163c60;
  --bs-btn-focus-shadow-rgb: 22, 60, 96;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #163c60;
  --bs-btn-active-border-color: #163c60;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #163c60;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #163c60;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #70e788;
  --bs-btn-border-color: #70e788;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #70e788;
  --bs-btn-hover-border-color: #70e788;
  --bs-btn-focus-shadow-rgb: 112, 231, 136;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #70e788;
  --bs-btn-active-border-color: #70e788;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #70e788;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #70e788;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 57, 89, 120;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.249999999rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .btn-lg {
    --bs-btn-font-size: 1.25rem;
  }
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.8749999993rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .btn-sm {
    --bs-btn-font-size: 0.875rem;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6666666667;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.249999999rem;
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.25rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.8749999993rem;
}
@media (min-width: 1200px) {
  .col-form-label-sm {
    font-size: 0.875rem;
  }
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control, select,
textarea,
input[type=text],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color] {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 0.9999999992rem;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .form-control, select,
  textarea,
  input[type=text],
  input[type=password],
  input[type=datetime],
  input[type=datetime-local],
  input[type=date],
  input[type=month],
  input[type=time],
  input[type=week],
  input[type=number],
  input[type=email],
  input[type=url],
  input[type=search],
  input[type=tel],
  input[type=color] {
    font-size: 1rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-control, select,
  textarea,
  input[type=text],
  input[type=password],
  input[type=datetime],
  input[type=datetime-local],
  input[type=date],
  input[type=month],
  input[type=time],
  input[type=week],
  input[type=number],
  input[type=email],
  input[type=url],
  input[type=search],
  input[type=tel],
  input[type=color] {
    transition: none;
  }
}
.form-control[type=file], select[type=file],
textarea[type=file],
input[type=file][type=text],
input[type=file][type=password],
input[type=file][type=datetime],
input[type=file][type=datetime-local],
input[type=file][type=date],
input[type=file][type=month],
input[type=file][type=time],
input[type=file][type=week],
input[type=file][type=number],
input[type=file][type=email],
input[type=file][type=url],
input[type=file][type=search],
input[type=file][type=tel],
input[type=file][type=color] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]), select[type=file]:not(:disabled):not([readonly]),
textarea[type=file]:not(:disabled):not([readonly]),
input[type=file][type=text]:not(:disabled):not([readonly]),
input[type=file][type=password]:not(:disabled):not([readonly]),
input[type=file][type=datetime]:not(:disabled):not([readonly]),
input[type=file][type=datetime-local]:not(:disabled):not([readonly]),
input[type=file][type=date]:not(:disabled):not([readonly]),
input[type=file][type=month]:not(:disabled):not([readonly]),
input[type=file][type=time]:not(:disabled):not([readonly]),
input[type=file][type=week]:not(:disabled):not([readonly]),
input[type=file][type=number]:not(:disabled):not([readonly]),
input[type=file][type=email]:not(:disabled):not([readonly]),
input[type=file][type=url]:not(:disabled):not([readonly]),
input[type=file][type=search]:not(:disabled):not([readonly]),
input[type=file][type=tel]:not(:disabled):not([readonly]),
input[type=file][type=color]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus, select:focus,
textarea:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=time]:focus,
input[type=week]:focus,
input[type=number]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=color]:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: rgb(138.5, 157.5, 175.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(22, 60, 96, 0.25);
}
.form-control::-webkit-date-and-time-value, select::-webkit-date-and-time-value,
textarea::-webkit-date-and-time-value,
input[type=text]::-webkit-date-and-time-value,
input[type=password]::-webkit-date-and-time-value,
input[type=datetime]::-webkit-date-and-time-value,
input[type=datetime-local]::-webkit-date-and-time-value,
input[type=date]::-webkit-date-and-time-value,
input[type=month]::-webkit-date-and-time-value,
input[type=time]::-webkit-date-and-time-value,
input[type=week]::-webkit-date-and-time-value,
input[type=number]::-webkit-date-and-time-value,
input[type=email]::-webkit-date-and-time-value,
input[type=url]::-webkit-date-and-time-value,
input[type=search]::-webkit-date-and-time-value,
input[type=tel]::-webkit-date-and-time-value,
input[type=color]::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.6666666667em;
  margin: 0;
}
.form-control::-webkit-datetime-edit, select::-webkit-datetime-edit,
textarea::-webkit-datetime-edit,
input[type=text]::-webkit-datetime-edit,
input[type=password]::-webkit-datetime-edit,
input[type=datetime]::-webkit-datetime-edit,
input[type=datetime-local]::-webkit-datetime-edit,
input[type=date]::-webkit-datetime-edit,
input[type=month]::-webkit-datetime-edit,
input[type=time]::-webkit-datetime-edit,
input[type=week]::-webkit-datetime-edit,
input[type=number]::-webkit-datetime-edit,
input[type=email]::-webkit-datetime-edit,
input[type=url]::-webkit-datetime-edit,
input[type=search]::-webkit-datetime-edit,
input[type=tel]::-webkit-datetime-edit,
input[type=color]::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder, select::placeholder,
textarea::placeholder,
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=datetime]::placeholder,
input[type=datetime-local]::placeholder,
input[type=date]::placeholder,
input[type=month]::placeholder,
input[type=time]::placeholder,
input[type=week]::placeholder,
input[type=number]::placeholder,
input[type=email]::placeholder,
input[type=url]::placeholder,
input[type=search]::placeholder,
input[type=tel]::placeholder,
input[type=color]::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled, select:disabled,
textarea:disabled,
input[type=text]:disabled,
input[type=password]:disabled,
input[type=datetime]:disabled,
input[type=datetime-local]:disabled,
input[type=date]:disabled,
input[type=month]:disabled,
input[type=time]:disabled,
input[type=week]:disabled,
input[type=number]:disabled,
input[type=email]:disabled,
input[type=url]:disabled,
input[type=search]:disabled,
input[type=tel]:disabled,
input[type=color]:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button, select::file-selector-button,
textarea::file-selector-button,
input[type=text]::file-selector-button,
input[type=password]::file-selector-button,
input[type=datetime]::file-selector-button,
input[type=datetime-local]::file-selector-button,
input[type=date]::file-selector-button,
input[type=month]::file-selector-button,
input[type=time]::file-selector-button,
input[type=week]::file-selector-button,
input[type=number]::file-selector-button,
input[type=email]::file-selector-button,
input[type=url]::file-selector-button,
input[type=search]::file-selector-button,
input[type=tel]::file-selector-button,
input[type=color]::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button, select::file-selector-button,
  textarea::file-selector-button,
  input[type=text]::file-selector-button,
  input[type=password]::file-selector-button,
  input[type=datetime]::file-selector-button,
  input[type=datetime-local]::file-selector-button,
  input[type=date]::file-selector-button,
  input[type=month]::file-selector-button,
  input[type=time]::file-selector-button,
  input[type=week]::file-selector-button,
  input[type=number]::file-selector-button,
  input[type=email]::file-selector-button,
  input[type=url]::file-selector-button,
  input[type=search]::file-selector-button,
  input[type=tel]::file-selector-button,
  input[type=color]::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button, select:hover:not(:disabled):not([readonly])::file-selector-button,
textarea:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=password]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=datetime]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=datetime-local]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=date]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=month]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=time]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=week]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=number]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=email]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=url]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=search]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=tel]:hover:not(:disabled):not([readonly])::file-selector-button,
input[type=color]:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6666666667;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6666666667em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.8749999993rem;
  border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .form-control-sm {
    font-size: 0.875rem;
  }
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.6666666667em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.249999999rem;
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.25rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control,
textarea {
  min-height: calc(1.6666666667em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.6666666667em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.6666666667em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.6666666667em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.6666666667em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.6666666667em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select, select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 0.9999999992rem;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .form-select, select {
    font-size: 1rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-select, select {
    transition: none;
  }
}
.form-select:focus, select:focus {
  border-color: rgb(138.5, 157.5, 175.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(22, 60, 96, 0.25);
}
.form-select[multiple], select[multiple], .form-select[size]:not([size="1"]), select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled, select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring, select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.8749999993rem;
  border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .form-select-sm {
    font-size: 0.875rem;
  }
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.249999999rem;
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.25rem;
  }
}

[data-bs-theme=dark] .form-select, [data-bs-theme=dark] select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check, .radio_container > span,
.checkbox_container > span {
  display: block;
  min-height: 1.6666666667rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input, .radio_container > span .form-check-input, .radio_container > span input,
.checkbox_container > span .form-check-input,
.checkbox_container > span input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input, .form-check-reverse .radio_container > span input, .radio_container > span .form-check-reverse input,
.form-check-reverse .checkbox_container > span input,
.checkbox_container > span .form-check-reverse input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input, .radio_container > span input,
.checkbox_container > span input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.3333333333em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox], .radio_container > span input[type=checkbox],
.checkbox_container > span input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio], .radio_container > span input[type=radio],
.checkbox_container > span input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active, .radio_container > span input:active,
.checkbox_container > span input:active {
  filter: brightness(90%);
}
.form-check-input:focus, .radio_container > span input:focus,
.checkbox_container > span input:focus {
  border-color: rgb(138.5, 157.5, 175.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(22, 60, 96, 0.25);
}
.form-check-input:checked, .radio_container > span input:checked,
.checkbox_container > span input:checked {
  background-color: #163c60;
  border-color: #163c60;
}
.form-check-input:checked[type=checkbox], .radio_container > span input:checked[type=checkbox],
.checkbox_container > span input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio], .radio_container > span input:checked[type=radio],
.checkbox_container > span input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate, .radio_container > span input[type=checkbox]:indeterminate,
.checkbox_container > span input[type=checkbox]:indeterminate {
  background-color: #163c60;
  border-color: #163c60;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled, .radio_container > span input:disabled,
.checkbox_container > span input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .radio_container > span .form-check-input[disabled] ~ label,
.checkbox_container > span .form-check-input[disabled] ~ label, .radio_container > span input[disabled] ~ .form-check-label, .radio_container > span input[disabled] ~ label,
.checkbox_container > span input[disabled] ~ .form-check-label,
.checkbox_container > span input[disabled] ~ label, .form-check-input:disabled ~ .form-check-label, .radio_container > span .form-check-input:disabled ~ label,
.checkbox_container > span .form-check-input:disabled ~ label, .radio_container > span input:disabled ~ .form-check-label, .radio_container > span input:disabled ~ label,
.checkbox_container > span input:disabled ~ .form-check-label,
.checkbox_container > span input:disabled ~ label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input, .form-switch .radio_container > span input, .radio_container > span .form-switch input,
.form-switch .checkbox_container > span input,
.checkbox_container > span .form-switch input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input, .form-switch .radio_container > span input, .radio_container > span .form-switch input,
  .form-switch .checkbox_container > span input,
  .checkbox_container > span .form-switch input {
    transition: none;
  }
}
.form-switch .form-check-input:focus, .form-switch .radio_container > span input:focus, .radio_container > span .form-switch input:focus,
.form-switch .checkbox_container > span input:focus,
.checkbox_container > span .form-switch input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb%28138.5, 157.5, 175.5%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked, .form-switch .radio_container > span input:checked, .radio_container > span .form-switch input:checked,
.form-switch .checkbox_container > span input:checked,
.checkbox_container > span .form-switch input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input, .form-switch.form-check-reverse .radio_container > span input, .radio_container > span .form-switch.form-check-reverse input,
.form-switch.form-check-reverse .checkbox_container > span input,
.checkbox_container > span .form-switch.form-check-reverse input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .link-more-btn .btn-check[disabled] + a, .formbody .btn-check[disabled] + .submit, .btn-check:disabled + .btn, .link-more-btn .btn-check:disabled + a, .formbody .btn-check:disabled + .submit {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus), [data-bs-theme=dark] .form-switch .radio_container > span input:not(:checked):not(:focus), .radio_container > span [data-bs-theme=dark] .form-switch input:not(:checked):not(:focus),
[data-bs-theme=dark] .form-switch .checkbox_container > span input:not(:checked):not(:focus),
.checkbox_container > span [data-bs-theme=dark] .form-switch input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(22, 60, 96, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(22, 60, 96, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #163c60;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgb(185.1, 196.5, 207.3);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #163c60;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgb(185.1, 196.5, 207.3);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .form-floating > select,
.form-floating > textarea,
.form-floating > input[type=text],
.form-floating > input[type=password],
.form-floating > input[type=datetime],
.form-floating > input[type=datetime-local],
.form-floating > input[type=date],
.form-floating > input[type=month],
.form-floating > input[type=time],
.form-floating > input[type=week],
.form-floating > input[type=number],
.form-floating > input[type=email],
.form-floating > input[type=url],
.form-floating > input[type=search],
.form-floating > input[type=tel],
.form-floating > input[type=color],
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control, .form-floating > select,
.form-floating > textarea,
.form-floating > input[type=text],
.form-floating > input[type=password],
.form-floating > input[type=datetime],
.form-floating > input[type=datetime-local],
.form-floating > input[type=date],
.form-floating > input[type=month],
.form-floating > input[type=time],
.form-floating > input[type=week],
.form-floating > input[type=number],
.form-floating > input[type=email],
.form-floating > input[type=url],
.form-floating > input[type=search],
.form-floating > input[type=tel],
.form-floating > input[type=color],
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder, .form-floating > select::placeholder,
.form-floating > textarea::placeholder,
.form-floating > input[type=text]::placeholder,
.form-floating > input[type=password]::placeholder,
.form-floating > input[type=datetime]::placeholder,
.form-floating > input[type=datetime-local]::placeholder,
.form-floating > input[type=date]::placeholder,
.form-floating > input[type=month]::placeholder,
.form-floating > input[type=time]::placeholder,
.form-floating > input[type=week]::placeholder,
.form-floating > input[type=number]::placeholder,
.form-floating > input[type=email]::placeholder,
.form-floating > input[type=url]::placeholder,
.form-floating > input[type=search]::placeholder,
.form-floating > input[type=tel]::placeholder,
.form-floating > input[type=color]::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > select:focus,
.form-floating > textarea:focus,
.form-floating > input[type=text]:focus,
.form-floating > input[type=password]:focus,
.form-floating > input[type=datetime]:focus,
.form-floating > input[type=datetime-local]:focus,
.form-floating > input[type=date]:focus,
.form-floating > input[type=month]:focus,
.form-floating > input[type=time]:focus,
.form-floating > input[type=week]:focus,
.form-floating > input[type=number]:focus,
.form-floating > input[type=email]:focus,
.form-floating > input[type=url]:focus,
.form-floating > input[type=search]:focus,
.form-floating > input[type=tel]:focus,
.form-floating > input[type=color]:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > select:not(:placeholder-shown),
.form-floating > textarea:not(:placeholder-shown),
.form-floating > input[type=text]:not(:placeholder-shown),
.form-floating > input[type=password]:not(:placeholder-shown),
.form-floating > input[type=datetime]:not(:placeholder-shown),
.form-floating > input[type=datetime-local]:not(:placeholder-shown),
.form-floating > input[type=date]:not(:placeholder-shown),
.form-floating > input[type=month]:not(:placeholder-shown),
.form-floating > input[type=time]:not(:placeholder-shown),
.form-floating > input[type=week]:not(:placeholder-shown),
.form-floating > input[type=number]:not(:placeholder-shown),
.form-floating > input[type=email]:not(:placeholder-shown),
.form-floating > input[type=url]:not(:placeholder-shown),
.form-floating > input[type=search]:not(:placeholder-shown),
.form-floating > input[type=tel]:not(:placeholder-shown),
.form-floating > input[type=color]:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill, .form-floating > select:-webkit-autofill,
.form-floating > textarea:-webkit-autofill,
.form-floating > input[type=text]:-webkit-autofill,
.form-floating > input[type=password]:-webkit-autofill,
.form-floating > input[type=datetime]:-webkit-autofill,
.form-floating > input[type=datetime-local]:-webkit-autofill,
.form-floating > input[type=date]:-webkit-autofill,
.form-floating > input[type=month]:-webkit-autofill,
.form-floating > input[type=time]:-webkit-autofill,
.form-floating > input[type=week]:-webkit-autofill,
.form-floating > input[type=number]:-webkit-autofill,
.form-floating > input[type=email]:-webkit-autofill,
.form-floating > input[type=url]:-webkit-autofill,
.form-floating > input[type=search]:-webkit-autofill,
.form-floating > input[type=tel]:-webkit-autofill,
.form-floating > input[type=color]:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select, .form-floating > select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > textarea:focus ~ label,
.form-floating > input[type=text]:focus ~ label,
.form-floating > input[type=password]:focus ~ label,
.form-floating > input[type=datetime]:focus ~ label,
.form-floating > input[type=datetime-local]:focus ~ label,
.form-floating > input[type=date]:focus ~ label,
.form-floating > input[type=month]:focus ~ label,
.form-floating > input[type=time]:focus ~ label,
.form-floating > input[type=week]:focus ~ label,
.form-floating > input[type=number]:focus ~ label,
.form-floating > input[type=email]:focus ~ label,
.form-floating > input[type=url]:focus ~ label,
.form-floating > input[type=search]:focus ~ label,
.form-floating > input[type=tel]:focus ~ label,
.form-floating > input[type=color]:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > textarea:not(:placeholder-shown) ~ label,
.form-floating > input[type=text]:not(:placeholder-shown) ~ label,
.form-floating > input[type=password]:not(:placeholder-shown) ~ label,
.form-floating > input[type=datetime]:not(:placeholder-shown) ~ label,
.form-floating > input[type=datetime-local]:not(:placeholder-shown) ~ label,
.form-floating > input[type=date]:not(:placeholder-shown) ~ label,
.form-floating > input[type=month]:not(:placeholder-shown) ~ label,
.form-floating > input[type=time]:not(:placeholder-shown) ~ label,
.form-floating > input[type=week]:not(:placeholder-shown) ~ label,
.form-floating > input[type=number]:not(:placeholder-shown) ~ label,
.form-floating > input[type=email]:not(:placeholder-shown) ~ label,
.form-floating > input[type=url]:not(:placeholder-shown) ~ label,
.form-floating > input[type=search]:not(:placeholder-shown) ~ label,
.form-floating > input[type=tel]:not(:placeholder-shown) ~ label,
.form-floating > input[type=color]:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label,
.form-floating > select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > textarea:focus ~ label::after,
.form-floating > input[type=text]:focus ~ label::after,
.form-floating > input[type=password]:focus ~ label::after,
.form-floating > input[type=datetime]:focus ~ label::after,
.form-floating > input[type=datetime-local]:focus ~ label::after,
.form-floating > input[type=date]:focus ~ label::after,
.form-floating > input[type=month]:focus ~ label::after,
.form-floating > input[type=time]:focus ~ label::after,
.form-floating > input[type=week]:focus ~ label::after,
.form-floating > input[type=number]:focus ~ label::after,
.form-floating > input[type=email]:focus ~ label::after,
.form-floating > input[type=url]:focus ~ label::after,
.form-floating > input[type=search]:focus ~ label::after,
.form-floating > input[type=tel]:focus ~ label::after,
.form-floating > input[type=color]:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > textarea:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=text]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=password]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=datetime]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=datetime-local]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=date]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=month]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=time]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=week]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=number]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=email]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=url]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=search]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=tel]:not(:placeholder-shown) ~ label::after,
.form-floating > input[type=color]:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after,
.form-floating > select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label, .form-floating > select:-webkit-autofill ~ label,
.form-floating > textarea:-webkit-autofill ~ label,
.form-floating > input[type=text]:-webkit-autofill ~ label,
.form-floating > input[type=password]:-webkit-autofill ~ label,
.form-floating > input[type=datetime]:-webkit-autofill ~ label,
.form-floating > input[type=datetime-local]:-webkit-autofill ~ label,
.form-floating > input[type=date]:-webkit-autofill ~ label,
.form-floating > input[type=month]:-webkit-autofill ~ label,
.form-floating > input[type=time]:-webkit-autofill ~ label,
.form-floating > input[type=week]:-webkit-autofill ~ label,
.form-floating > input[type=number]:-webkit-autofill ~ label,
.form-floating > input[type=email]:-webkit-autofill ~ label,
.form-floating > input[type=url]:-webkit-autofill ~ label,
.form-floating > input[type=search]:-webkit-autofill ~ label,
.form-floating > input[type=tel]:-webkit-autofill ~ label,
.form-floating > input[type=color]:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .input-group > select,
.input-group > textarea,
.input-group > input[type=text],
.input-group > input[type=password],
.input-group > input[type=datetime],
.input-group > input[type=datetime-local],
.input-group > input[type=date],
.input-group > input[type=month],
.input-group > input[type=time],
.input-group > input[type=week],
.input-group > input[type=number],
.input-group > input[type=email],
.input-group > input[type=url],
.input-group > input[type=search],
.input-group > input[type=tel],
.input-group > input[type=color],
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .input-group > select:focus,
.input-group > textarea:focus,
.input-group > input[type=text]:focus,
.input-group > input[type=password]:focus,
.input-group > input[type=datetime]:focus,
.input-group > input[type=datetime-local]:focus,
.input-group > input[type=date]:focus,
.input-group > input[type=month]:focus,
.input-group > input[type=time]:focus,
.input-group > input[type=week]:focus,
.input-group > input[type=number]:focus,
.input-group > input[type=email]:focus,
.input-group > input[type=url]:focus,
.input-group > input[type=search]:focus,
.input-group > input[type=tel]:focus,
.input-group > input[type=color]:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn, .input-group .link-more-btn a, .link-more-btn .input-group a, .input-group .formbody .submit, .formbody .input-group .submit {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .link-more-btn a:focus, .link-more-btn .input-group a:focus, .input-group .formbody .submit:focus, .formbody .input-group .submit:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9999999992rem;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}
@media (min-width: 1200px) {
  .input-group-text {
    font-size: 1rem;
  }
}

.input-group-lg > .form-control, .input-group-lg > select,
.input-group-lg > textarea,
.input-group-lg > input[type=text],
.input-group-lg > input[type=password],
.input-group-lg > input[type=datetime],
.input-group-lg > input[type=datetime-local],
.input-group-lg > input[type=date],
.input-group-lg > input[type=month],
.input-group-lg > input[type=time],
.input-group-lg > input[type=week],
.input-group-lg > input[type=number],
.input-group-lg > input[type=email],
.input-group-lg > input[type=url],
.input-group-lg > input[type=search],
.input-group-lg > input[type=tel],
.input-group-lg > input[type=color],
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.link-more-btn .input-group-lg > a,
.formbody .input-group-lg > .submit {
  padding: 0.5rem 1rem;
  font-size: 1.249999999rem;
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control, .input-group-lg > select,
  .input-group-lg > textarea,
  .input-group-lg > input[type=text],
  .input-group-lg > input[type=password],
  .input-group-lg > input[type=datetime],
  .input-group-lg > input[type=datetime-local],
  .input-group-lg > input[type=date],
  .input-group-lg > input[type=month],
  .input-group-lg > input[type=time],
  .input-group-lg > input[type=week],
  .input-group-lg > input[type=number],
  .input-group-lg > input[type=email],
  .input-group-lg > input[type=url],
  .input-group-lg > input[type=search],
  .input-group-lg > input[type=tel],
  .input-group-lg > input[type=color],
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn,
  .link-more-btn .input-group-lg > a,
  .formbody .input-group-lg > .submit {
    font-size: 1.25rem;
  }
}

.input-group-sm > .form-control, .input-group-sm > select,
.input-group-sm > textarea,
.input-group-sm > input[type=text],
.input-group-sm > input[type=password],
.input-group-sm > input[type=datetime],
.input-group-sm > input[type=datetime-local],
.input-group-sm > input[type=date],
.input-group-sm > input[type=month],
.input-group-sm > input[type=time],
.input-group-sm > input[type=week],
.input-group-sm > input[type=number],
.input-group-sm > input[type=email],
.input-group-sm > input[type=url],
.input-group-sm > input[type=search],
.input-group-sm > input[type=tel],
.input-group-sm > input[type=color],
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.link-more-btn .input-group-sm > a,
.formbody .input-group-sm > .submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.8749999993rem;
  border-radius: var(--bs-border-radius-sm);
}
@media (min-width: 1200px) {
  .input-group-sm > .form-control, .input-group-sm > select,
  .input-group-sm > textarea,
  .input-group-sm > input[type=text],
  .input-group-sm > input[type=password],
  .input-group-sm > input[type=datetime],
  .input-group-sm > input[type=datetime-local],
  .input-group-sm > input[type=date],
  .input-group-sm > input[type=month],
  .input-group-sm > input[type=time],
  .input-group-sm > input[type=week],
  .input-group-sm > input[type=number],
  .input-group-sm > input[type=email],
  .input-group-sm > input[type=url],
  .input-group-sm > input[type=search],
  .input-group-sm > input[type=tel],
  .input-group-sm > input[type=color],
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn,
  .link-more-btn .input-group-sm > a,
  .formbody .input-group-sm > .submit {
    font-size: 0.875rem;
  }
}

.input-group-lg > .form-select, .input-group-lg > select,
.input-group-sm > .form-select,
.input-group-sm > select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > select,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > textarea,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=text],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=password],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=datetime],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=datetime-local],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=date],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=month],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=time],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=week],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=number],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=email],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=url],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=search],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=tel],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type=color],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > select,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > textarea,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=text],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=password],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=datetime],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=datetime-local],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=date],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=month],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=time],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=week],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=number],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=email],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=url],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=search],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=tel],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input[type=color],
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > select,
.input-group > .form-floating:not(:first-child) > textarea,
.input-group > .form-floating:not(:first-child) > input[type=text],
.input-group > .form-floating:not(:first-child) > input[type=password],
.input-group > .form-floating:not(:first-child) > input[type=datetime],
.input-group > .form-floating:not(:first-child) > input[type=datetime-local],
.input-group > .form-floating:not(:first-child) > input[type=date],
.input-group > .form-floating:not(:first-child) > input[type=month],
.input-group > .form-floating:not(:first-child) > input[type=time],
.input-group > .form-floating:not(:first-child) > input[type=week],
.input-group > .form-floating:not(:first-child) > input[type=number],
.input-group > .form-floating:not(:first-child) > input[type=email],
.input-group > .form-floating:not(:first-child) > input[type=url],
.input-group > .form-floating:not(:first-child) > input[type=search],
.input-group > .form-floating:not(:first-child) > input[type=tel],
.input-group > .form-floating:not(:first-child) > input[type=color],
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8749999993rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}
@media (min-width: 1200px) {
  .valid-tooltip {
    font-size: 0.875rem;
  }
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated select:valid,
.was-validated textarea:valid,
.was-validated input[type=text]:valid,
.was-validated input[type=password]:valid,
.was-validated input[type=datetime]:valid,
.was-validated input[type=datetime-local]:valid,
.was-validated input[type=date]:valid,
.was-validated input[type=month]:valid,
.was-validated input[type=time]:valid,
.was-validated input[type=week]:valid,
.was-validated input[type=number]:valid,
.was-validated input[type=email]:valid,
.was-validated input[type=url]:valid,
.was-validated input[type=search]:valid,
.was-validated input[type=tel]:valid,
.was-validated input[type=color]:valid, .form-control.is-valid, select.is-valid,
textarea.is-valid,
input.is-valid[type=text],
input.is-valid[type=password],
input.is-valid[type=datetime],
input.is-valid[type=datetime-local],
input.is-valid[type=date],
input.is-valid[type=month],
input.is-valid[type=time],
input.is-valid[type=week],
input.is-valid[type=number],
input.is-valid[type=email],
input.is-valid[type=url],
input.is-valid[type=search],
input.is-valid[type=tel],
input.is-valid[type=color] {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.6666666667em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4166666667em + 0.1875rem) center;
  background-size: calc(0.8333333333em + 0.375rem) calc(0.8333333333em + 0.375rem);
}
.was-validated .form-control:valid:focus, .was-validated select:valid:focus,
.was-validated textarea:valid:focus,
.was-validated input[type=text]:valid:focus,
.was-validated input[type=password]:valid:focus,
.was-validated input[type=datetime]:valid:focus,
.was-validated input[type=datetime-local]:valid:focus,
.was-validated input[type=date]:valid:focus,
.was-validated input[type=month]:valid:focus,
.was-validated input[type=time]:valid:focus,
.was-validated input[type=week]:valid:focus,
.was-validated input[type=number]:valid:focus,
.was-validated input[type=email]:valid:focus,
.was-validated input[type=url]:valid:focus,
.was-validated input[type=search]:valid:focus,
.was-validated input[type=tel]:valid:focus,
.was-validated input[type=color]:valid:focus, .form-control.is-valid:focus, select.is-valid:focus,
textarea.is-valid:focus,
input.is-valid[type=text]:focus,
input.is-valid[type=password]:focus,
input.is-valid[type=datetime]:focus,
input.is-valid[type=datetime-local]:focus,
input.is-valid[type=date]:focus,
input.is-valid[type=month]:focus,
input.is-valid[type=time]:focus,
input.is-valid[type=week]:focus,
input.is-valid[type=number]:focus,
input.is-valid[type=email]:focus,
input.is-valid[type=url]:focus,
input.is-valid[type=search]:focus,
input.is-valid[type=tel]:focus,
input.is-valid[type=color]:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated textarea:valid, textarea.form-control.is-valid,
textarea.is-valid {
  padding-right: calc(1.6666666667em + 0.75rem);
  background-position: top calc(0.4166666667em + 0.1875rem) right calc(0.4166666667em + 0.1875rem);
}

.was-validated .form-select:valid, .was-validated select:valid, .form-select.is-valid, select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .was-validated select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8333333333em + 0.375rem) calc(0.8333333333em + 0.375rem);
}
.was-validated .form-select:valid:focus, .was-validated select:valid:focus, .form-select.is-valid:focus, select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.6666666667em + 0.75rem));
}

.was-validated .form-check-input:valid, .was-validated .radio_container > span input:valid, .radio_container > span .was-validated input:valid,
.was-validated .checkbox_container > span input:valid,
.checkbox_container > span .was-validated input:valid, .form-check-input.is-valid, .radio_container > span input.is-valid,
.checkbox_container > span input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .was-validated .radio_container > span input:valid:checked, .radio_container > span .was-validated input:valid:checked,
.was-validated .checkbox_container > span input:valid:checked,
.checkbox_container > span .was-validated input:valid:checked, .form-check-input.is-valid:checked, .radio_container > span input.is-valid:checked,
.checkbox_container > span input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .was-validated .radio_container > span input:valid:focus, .radio_container > span .was-validated input:valid:focus,
.was-validated .checkbox_container > span input:valid:focus,
.checkbox_container > span .was-validated input:valid:focus, .form-check-input.is-valid:focus, .radio_container > span input.is-valid:focus,
.checkbox_container > span input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .was-validated .radio_container > span .form-check-input:valid ~ label, .radio_container > span .was-validated .form-check-input:valid ~ label,
.was-validated .checkbox_container > span .form-check-input:valid ~ label,
.checkbox_container > span .was-validated .form-check-input:valid ~ label, .was-validated .radio_container > span input:valid ~ .form-check-label, .was-validated .radio_container > span input:valid ~ label, .radio_container > span .was-validated input:valid ~ .form-check-label, .radio_container > span .was-validated input:valid ~ label,
.was-validated .checkbox_container > span input:valid ~ .form-check-label,
.was-validated .checkbox_container > span input:valid ~ label,
.checkbox_container > span .was-validated input:valid ~ .form-check-label,
.checkbox_container > span .was-validated input:valid ~ label, .form-check-input.is-valid ~ .form-check-label, .radio_container > span .form-check-input.is-valid ~ label,
.checkbox_container > span .form-check-input.is-valid ~ label, .radio_container > span input.is-valid ~ .form-check-label, .radio_container > span input.is-valid ~ label,
.checkbox_container > span input.is-valid ~ .form-check-label,
.checkbox_container > span input.is-valid ~ label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback, .form-check-inline .radio_container > span input ~ .valid-feedback, .radio_container > span .form-check-inline input ~ .valid-feedback,
.form-check-inline .checkbox_container > span input ~ .valid-feedback,
.checkbox_container > span .form-check-inline input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > select:not(:focus):valid,
.was-validated .input-group > textarea:not(:focus):valid,
.was-validated .input-group > input[type=text]:not(:focus):valid,
.was-validated .input-group > input[type=password]:not(:focus):valid,
.was-validated .input-group > input[type=datetime]:not(:focus):valid,
.was-validated .input-group > input[type=datetime-local]:not(:focus):valid,
.was-validated .input-group > input[type=date]:not(:focus):valid,
.was-validated .input-group > input[type=month]:not(:focus):valid,
.was-validated .input-group > input[type=time]:not(:focus):valid,
.was-validated .input-group > input[type=week]:not(:focus):valid,
.was-validated .input-group > input[type=number]:not(:focus):valid,
.was-validated .input-group > input[type=email]:not(:focus):valid,
.was-validated .input-group > input[type=url]:not(:focus):valid,
.was-validated .input-group > input[type=search]:not(:focus):valid,
.was-validated .input-group > input[type=tel]:not(:focus):valid,
.was-validated .input-group > input[type=color]:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .input-group > select:not(:focus).is-valid,
.input-group > textarea:not(:focus).is-valid,
.input-group > input[type=text]:not(:focus).is-valid,
.input-group > input[type=password]:not(:focus).is-valid,
.input-group > input[type=datetime]:not(:focus).is-valid,
.input-group > input[type=datetime-local]:not(:focus).is-valid,
.input-group > input[type=date]:not(:focus).is-valid,
.input-group > input[type=month]:not(:focus).is-valid,
.input-group > input[type=time]:not(:focus).is-valid,
.input-group > input[type=week]:not(:focus).is-valid,
.input-group > input[type=number]:not(:focus).is-valid,
.input-group > input[type=email]:not(:focus).is-valid,
.input-group > input[type=url]:not(:focus).is-valid,
.input-group > input[type=search]:not(:focus).is-valid,
.input-group > input[type=tel]:not(:focus).is-valid,
.input-group > input[type=color]:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8749999993rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}
@media (min-width: 1200px) {
  .invalid-tooltip {
    font-size: 0.875rem;
  }
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
select.error ~ .invalid-feedback,
textarea.error ~ .invalid-feedback,
input[type=text].error ~ .invalid-feedback,
input[type=password].error ~ .invalid-feedback,
input[type=datetime].error ~ .invalid-feedback,
input[type=datetime-local].error ~ .invalid-feedback,
input[type=date].error ~ .invalid-feedback,
input[type=month].error ~ .invalid-feedback,
input[type=time].error ~ .invalid-feedback,
input[type=week].error ~ .invalid-feedback,
input[type=number].error ~ .invalid-feedback,
input[type=email].error ~ .invalid-feedback,
input[type=url].error ~ .invalid-feedback,
input[type=search].error ~ .invalid-feedback,
input[type=tel].error ~ .invalid-feedback,
input[type=color].error ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
select.error ~ .invalid-tooltip,
textarea.error ~ .invalid-tooltip,
input[type=text].error ~ .invalid-tooltip,
input[type=password].error ~ .invalid-tooltip,
input[type=datetime].error ~ .invalid-tooltip,
input[type=datetime-local].error ~ .invalid-tooltip,
input[type=date].error ~ .invalid-tooltip,
input[type=month].error ~ .invalid-tooltip,
input[type=time].error ~ .invalid-tooltip,
input[type=week].error ~ .invalid-tooltip,
input[type=number].error ~ .invalid-tooltip,
input[type=email].error ~ .invalid-tooltip,
input[type=url].error ~ .invalid-tooltip,
input[type=search].error ~ .invalid-tooltip,
input[type=tel].error ~ .invalid-tooltip,
input[type=color].error ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated select:invalid,
.was-validated textarea:invalid,
.was-validated input[type=text]:invalid,
.was-validated input[type=password]:invalid,
.was-validated input[type=datetime]:invalid,
.was-validated input[type=datetime-local]:invalid,
.was-validated input[type=date]:invalid,
.was-validated input[type=month]:invalid,
.was-validated input[type=time]:invalid,
.was-validated input[type=week]:invalid,
.was-validated input[type=number]:invalid,
.was-validated input[type=email]:invalid,
.was-validated input[type=url]:invalid,
.was-validated input[type=search]:invalid,
.was-validated input[type=tel]:invalid,
.was-validated input[type=color]:invalid, .form-control.is-invalid, select.is-invalid, select.error,
textarea.is-invalid,
textarea.error,
input.is-invalid[type=text],
input[type=text].error,
input.is-invalid[type=password],
input[type=password].error,
input.is-invalid[type=datetime],
input[type=datetime].error,
input.is-invalid[type=datetime-local],
input[type=datetime-local].error,
input.is-invalid[type=date],
input[type=date].error,
input.is-invalid[type=month],
input[type=month].error,
input.is-invalid[type=time],
input[type=time].error,
input.is-invalid[type=week],
input[type=week].error,
input.is-invalid[type=number],
input[type=number].error,
input.is-invalid[type=email],
input[type=email].error,
input.is-invalid[type=url],
input[type=url].error,
input.is-invalid[type=search],
input[type=search].error,
input.is-invalid[type=tel],
input[type=tel].error,
input.is-invalid[type=color],
input[type=color].error {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.6666666667em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4166666667em + 0.1875rem) center;
  background-size: calc(0.8333333333em + 0.375rem) calc(0.8333333333em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .was-validated select:invalid:focus,
.was-validated textarea:invalid:focus,
.was-validated input[type=text]:invalid:focus,
.was-validated input[type=password]:invalid:focus,
.was-validated input[type=datetime]:invalid:focus,
.was-validated input[type=datetime-local]:invalid:focus,
.was-validated input[type=date]:invalid:focus,
.was-validated input[type=month]:invalid:focus,
.was-validated input[type=time]:invalid:focus,
.was-validated input[type=week]:invalid:focus,
.was-validated input[type=number]:invalid:focus,
.was-validated input[type=email]:invalid:focus,
.was-validated input[type=url]:invalid:focus,
.was-validated input[type=search]:invalid:focus,
.was-validated input[type=tel]:invalid:focus,
.was-validated input[type=color]:invalid:focus, .form-control.is-invalid:focus, select.is-invalid:focus, select.error:focus,
textarea.is-invalid:focus,
textarea.error:focus,
input.is-invalid[type=text]:focus,
input[type=text].error:focus,
input.is-invalid[type=password]:focus,
input[type=password].error:focus,
input.is-invalid[type=datetime]:focus,
input[type=datetime].error:focus,
input.is-invalid[type=datetime-local]:focus,
input[type=datetime-local].error:focus,
input.is-invalid[type=date]:focus,
input[type=date].error:focus,
input.is-invalid[type=month]:focus,
input[type=month].error:focus,
input.is-invalid[type=time]:focus,
input[type=time].error:focus,
input.is-invalid[type=week]:focus,
input[type=week].error:focus,
input.is-invalid[type=number]:focus,
input[type=number].error:focus,
input.is-invalid[type=email]:focus,
input[type=email].error:focus,
input.is-invalid[type=url]:focus,
input[type=url].error:focus,
input.is-invalid[type=search]:focus,
input[type=search].error:focus,
input.is-invalid[type=tel]:focus,
input[type=tel].error:focus,
input.is-invalid[type=color]:focus,
input[type=color].error:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated textarea:invalid, textarea.form-control.is-invalid,
textarea.is-invalid,
textarea.error {
  padding-right: calc(1.6666666667em + 0.75rem);
  background-position: top calc(0.4166666667em + 0.1875rem) right calc(0.4166666667em + 0.1875rem);
}

.was-validated .form-select:invalid, .was-validated select:invalid, .form-select.is-invalid, select.is-invalid, select.error,
textarea.form-select.error,
input.form-select[type=text].error,
input.form-select[type=password].error,
input.form-select[type=datetime].error,
input.form-select[type=datetime-local].error,
input.form-select[type=date].error,
input.form-select[type=month].error,
input.form-select[type=time].error,
input.form-select[type=week].error,
input.form-select[type=number].error,
input.form-select[type=email].error,
input.form-select[type=url].error,
input.form-select[type=search].error,
input.form-select[type=tel].error,
input.form-select[type=color].error {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .was-validated select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), select.is-invalid:not([multiple]):not([size]), select.error:not([multiple]):not([size]),
textarea.form-select.error:not([multiple]):not([size]),
input.form-select[type=text].error:not([multiple]):not([size]),
input.form-select[type=password].error:not([multiple]):not([size]),
input.form-select[type=datetime].error:not([multiple]):not([size]),
input.form-select[type=datetime-local].error:not([multiple]):not([size]),
input.form-select[type=date].error:not([multiple]):not([size]),
input.form-select[type=month].error:not([multiple]):not([size]),
input.form-select[type=time].error:not([multiple]):not([size]),
input.form-select[type=week].error:not([multiple]):not([size]),
input.form-select[type=number].error:not([multiple]):not([size]),
input.form-select[type=email].error:not([multiple]):not([size]),
input.form-select[type=url].error:not([multiple]):not([size]),
input.form-select[type=search].error:not([multiple]):not([size]),
input.form-select[type=tel].error:not([multiple]):not([size]),
input.form-select[type=color].error:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], select.is-invalid:not([multiple])[size="1"], select.error:not([multiple])[size="1"],
textarea.form-select.error:not([multiple])[size="1"],
input.form-select[type=text].error:not([multiple])[size="1"],
input.form-select[type=password].error:not([multiple])[size="1"],
input.form-select[type=datetime].error:not([multiple])[size="1"],
input.form-select[type=datetime-local].error:not([multiple])[size="1"],
input.form-select[type=date].error:not([multiple])[size="1"],
input.form-select[type=month].error:not([multiple])[size="1"],
input.form-select[type=time].error:not([multiple])[size="1"],
input.form-select[type=week].error:not([multiple])[size="1"],
input.form-select[type=number].error:not([multiple])[size="1"],
input.form-select[type=email].error:not([multiple])[size="1"],
input.form-select[type=url].error:not([multiple])[size="1"],
input.form-select[type=search].error:not([multiple])[size="1"],
input.form-select[type=tel].error:not([multiple])[size="1"],
input.form-select[type=color].error:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8333333333em + 0.375rem) calc(0.8333333333em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .was-validated select:invalid:focus, .form-select.is-invalid:focus, select.is-invalid:focus, select.error:focus,
textarea.form-select.error:focus,
input.form-select[type=text].error:focus,
input.form-select[type=password].error:focus,
input.form-select[type=datetime].error:focus,
input.form-select[type=datetime-local].error:focus,
input.form-select[type=date].error:focus,
input.form-select[type=month].error:focus,
input.form-select[type=time].error:focus,
input.form-select[type=week].error:focus,
input.form-select[type=number].error:focus,
input.form-select[type=email].error:focus,
input.form-select[type=url].error:focus,
input.form-select[type=search].error:focus,
input.form-select[type=tel].error:focus,
input.form-select[type=color].error:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid, select.form-control-color.error,
textarea.form-control-color.error,
input.form-control-color[type=text].error,
input.form-control-color[type=password].error,
input.form-control-color[type=datetime].error,
input.form-control-color[type=datetime-local].error,
input.form-control-color[type=date].error,
input.form-control-color[type=month].error,
input.form-control-color[type=time].error,
input.form-control-color[type=week].error,
input.form-control-color[type=number].error,
input.form-control-color[type=email].error,
input.form-control-color[type=url].error,
input.form-control-color[type=search].error,
input.form-control-color[type=tel].error,
input.form-control-color[type=color].error {
  width: calc(3rem + calc(1.6666666667em + 0.75rem));
}

.was-validated .form-check-input:invalid, .was-validated .radio_container > span input:invalid, .radio_container > span .was-validated input:invalid,
.was-validated .checkbox_container > span input:invalid,
.checkbox_container > span .was-validated input:invalid, .form-check-input.is-invalid, .radio_container > span input.is-invalid,
.checkbox_container > span input.is-invalid, select.form-check-input.error,
textarea.form-check-input.error,
input.form-check-input[type=text].error,
.radio_container > span input[type=text].error,
.checkbox_container > span input[type=text].error,
input.form-check-input[type=password].error,
.radio_container > span input[type=password].error,
.checkbox_container > span input[type=password].error,
input.form-check-input[type=datetime].error,
.radio_container > span input[type=datetime].error,
.checkbox_container > span input[type=datetime].error,
input.form-check-input[type=datetime-local].error,
.radio_container > span input[type=datetime-local].error,
.checkbox_container > span input[type=datetime-local].error,
input.form-check-input[type=date].error,
.radio_container > span input[type=date].error,
.checkbox_container > span input[type=date].error,
input.form-check-input[type=month].error,
.radio_container > span input[type=month].error,
.checkbox_container > span input[type=month].error,
input.form-check-input[type=time].error,
.radio_container > span input[type=time].error,
.checkbox_container > span input[type=time].error,
input.form-check-input[type=week].error,
.radio_container > span input[type=week].error,
.checkbox_container > span input[type=week].error,
input.form-check-input[type=number].error,
.radio_container > span input[type=number].error,
.checkbox_container > span input[type=number].error,
input.form-check-input[type=email].error,
.radio_container > span input[type=email].error,
.checkbox_container > span input[type=email].error,
input.form-check-input[type=url].error,
.radio_container > span input[type=url].error,
.checkbox_container > span input[type=url].error,
input.form-check-input[type=search].error,
.radio_container > span input[type=search].error,
.checkbox_container > span input[type=search].error,
input.form-check-input[type=tel].error,
.radio_container > span input[type=tel].error,
.checkbox_container > span input[type=tel].error,
input.form-check-input[type=color].error,
.radio_container > span input[type=color].error,
.checkbox_container > span input[type=color].error {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .was-validated .radio_container > span input:invalid:checked, .radio_container > span .was-validated input:invalid:checked,
.was-validated .checkbox_container > span input:invalid:checked,
.checkbox_container > span .was-validated input:invalid:checked, .form-check-input.is-invalid:checked, .radio_container > span input.is-invalid:checked,
.checkbox_container > span input.is-invalid:checked, select.form-check-input.error:checked,
textarea.form-check-input.error:checked,
input.form-check-input[type=text].error:checked,
.radio_container > span input[type=text].error:checked,
.checkbox_container > span input[type=text].error:checked,
input.form-check-input[type=password].error:checked,
.radio_container > span input[type=password].error:checked,
.checkbox_container > span input[type=password].error:checked,
input.form-check-input[type=datetime].error:checked,
.radio_container > span input[type=datetime].error:checked,
.checkbox_container > span input[type=datetime].error:checked,
input.form-check-input[type=datetime-local].error:checked,
.radio_container > span input[type=datetime-local].error:checked,
.checkbox_container > span input[type=datetime-local].error:checked,
input.form-check-input[type=date].error:checked,
.radio_container > span input[type=date].error:checked,
.checkbox_container > span input[type=date].error:checked,
input.form-check-input[type=month].error:checked,
.radio_container > span input[type=month].error:checked,
.checkbox_container > span input[type=month].error:checked,
input.form-check-input[type=time].error:checked,
.radio_container > span input[type=time].error:checked,
.checkbox_container > span input[type=time].error:checked,
input.form-check-input[type=week].error:checked,
.radio_container > span input[type=week].error:checked,
.checkbox_container > span input[type=week].error:checked,
input.form-check-input[type=number].error:checked,
.radio_container > span input[type=number].error:checked,
.checkbox_container > span input[type=number].error:checked,
input.form-check-input[type=email].error:checked,
.radio_container > span input[type=email].error:checked,
.checkbox_container > span input[type=email].error:checked,
input.form-check-input[type=url].error:checked,
.radio_container > span input[type=url].error:checked,
.checkbox_container > span input[type=url].error:checked,
input.form-check-input[type=search].error:checked,
.radio_container > span input[type=search].error:checked,
.checkbox_container > span input[type=search].error:checked,
input.form-check-input[type=tel].error:checked,
.radio_container > span input[type=tel].error:checked,
.checkbox_container > span input[type=tel].error:checked,
input.form-check-input[type=color].error:checked,
.radio_container > span input[type=color].error:checked,
.checkbox_container > span input[type=color].error:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .was-validated .radio_container > span input:invalid:focus, .radio_container > span .was-validated input:invalid:focus,
.was-validated .checkbox_container > span input:invalid:focus,
.checkbox_container > span .was-validated input:invalid:focus, .form-check-input.is-invalid:focus, .radio_container > span input.is-invalid:focus,
.checkbox_container > span input.is-invalid:focus, select.form-check-input.error:focus,
textarea.form-check-input.error:focus,
input.form-check-input[type=text].error:focus,
.radio_container > span input[type=text].error:focus,
.checkbox_container > span input[type=text].error:focus,
input.form-check-input[type=password].error:focus,
.radio_container > span input[type=password].error:focus,
.checkbox_container > span input[type=password].error:focus,
input.form-check-input[type=datetime].error:focus,
.radio_container > span input[type=datetime].error:focus,
.checkbox_container > span input[type=datetime].error:focus,
input.form-check-input[type=datetime-local].error:focus,
.radio_container > span input[type=datetime-local].error:focus,
.checkbox_container > span input[type=datetime-local].error:focus,
input.form-check-input[type=date].error:focus,
.radio_container > span input[type=date].error:focus,
.checkbox_container > span input[type=date].error:focus,
input.form-check-input[type=month].error:focus,
.radio_container > span input[type=month].error:focus,
.checkbox_container > span input[type=month].error:focus,
input.form-check-input[type=time].error:focus,
.radio_container > span input[type=time].error:focus,
.checkbox_container > span input[type=time].error:focus,
input.form-check-input[type=week].error:focus,
.radio_container > span input[type=week].error:focus,
.checkbox_container > span input[type=week].error:focus,
input.form-check-input[type=number].error:focus,
.radio_container > span input[type=number].error:focus,
.checkbox_container > span input[type=number].error:focus,
input.form-check-input[type=email].error:focus,
.radio_container > span input[type=email].error:focus,
.checkbox_container > span input[type=email].error:focus,
input.form-check-input[type=url].error:focus,
.radio_container > span input[type=url].error:focus,
.checkbox_container > span input[type=url].error:focus,
input.form-check-input[type=search].error:focus,
.radio_container > span input[type=search].error:focus,
.checkbox_container > span input[type=search].error:focus,
input.form-check-input[type=tel].error:focus,
.radio_container > span input[type=tel].error:focus,
.checkbox_container > span input[type=tel].error:focus,
input.form-check-input[type=color].error:focus,
.radio_container > span input[type=color].error:focus,
.checkbox_container > span input[type=color].error:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .was-validated .radio_container > span .form-check-input:invalid ~ label, .radio_container > span .was-validated .form-check-input:invalid ~ label,
.was-validated .checkbox_container > span .form-check-input:invalid ~ label,
.checkbox_container > span .was-validated .form-check-input:invalid ~ label, .was-validated .radio_container > span input:invalid ~ .form-check-label, .was-validated .radio_container > span input:invalid ~ label, .radio_container > span .was-validated input:invalid ~ .form-check-label, .radio_container > span .was-validated input:invalid ~ label,
.was-validated .checkbox_container > span input:invalid ~ .form-check-label,
.was-validated .checkbox_container > span input:invalid ~ label,
.checkbox_container > span .was-validated input:invalid ~ .form-check-label,
.checkbox_container > span .was-validated input:invalid ~ label, .form-check-input.is-invalid ~ .form-check-label, .radio_container > span .form-check-input.is-invalid ~ label,
.checkbox_container > span .form-check-input.is-invalid ~ label, .radio_container > span input.is-invalid ~ .form-check-label, .radio_container > span input.is-invalid ~ label,
.checkbox_container > span input.is-invalid ~ .form-check-label,
.checkbox_container > span input.is-invalid ~ label, select.form-check-input.error ~ .form-check-label, .radio_container > span select.form-check-input.error ~ label,
.checkbox_container > span select.form-check-input.error ~ label,
textarea.form-check-input.error ~ .form-check-label,
.radio_container > span textarea.form-check-input.error ~ label,
.checkbox_container > span textarea.form-check-input.error ~ label,
input.form-check-input[type=text].error ~ .form-check-label,
.radio_container > span input[type=text].error ~ .form-check-label,
.radio_container > span input[type=text].error ~ label,
.checkbox_container > span input[type=text].error ~ .form-check-label,
.checkbox_container > span input[type=text].error ~ label,
input.form-check-input[type=password].error ~ .form-check-label,
.radio_container > span input[type=password].error ~ .form-check-label,
.radio_container > span input[type=password].error ~ label,
.checkbox_container > span input[type=password].error ~ .form-check-label,
.checkbox_container > span input[type=password].error ~ label,
input.form-check-input[type=datetime].error ~ .form-check-label,
.radio_container > span input[type=datetime].error ~ .form-check-label,
.radio_container > span input[type=datetime].error ~ label,
.checkbox_container > span input[type=datetime].error ~ .form-check-label,
.checkbox_container > span input[type=datetime].error ~ label,
input.form-check-input[type=datetime-local].error ~ .form-check-label,
.radio_container > span input[type=datetime-local].error ~ .form-check-label,
.radio_container > span input[type=datetime-local].error ~ label,
.checkbox_container > span input[type=datetime-local].error ~ .form-check-label,
.checkbox_container > span input[type=datetime-local].error ~ label,
input.form-check-input[type=date].error ~ .form-check-label,
.radio_container > span input[type=date].error ~ .form-check-label,
.radio_container > span input[type=date].error ~ label,
.checkbox_container > span input[type=date].error ~ .form-check-label,
.checkbox_container > span input[type=date].error ~ label,
input.form-check-input[type=month].error ~ .form-check-label,
.radio_container > span input[type=month].error ~ .form-check-label,
.radio_container > span input[type=month].error ~ label,
.checkbox_container > span input[type=month].error ~ .form-check-label,
.checkbox_container > span input[type=month].error ~ label,
input.form-check-input[type=time].error ~ .form-check-label,
.radio_container > span input[type=time].error ~ .form-check-label,
.radio_container > span input[type=time].error ~ label,
.checkbox_container > span input[type=time].error ~ .form-check-label,
.checkbox_container > span input[type=time].error ~ label,
input.form-check-input[type=week].error ~ .form-check-label,
.radio_container > span input[type=week].error ~ .form-check-label,
.radio_container > span input[type=week].error ~ label,
.checkbox_container > span input[type=week].error ~ .form-check-label,
.checkbox_container > span input[type=week].error ~ label,
input.form-check-input[type=number].error ~ .form-check-label,
.radio_container > span input[type=number].error ~ .form-check-label,
.radio_container > span input[type=number].error ~ label,
.checkbox_container > span input[type=number].error ~ .form-check-label,
.checkbox_container > span input[type=number].error ~ label,
input.form-check-input[type=email].error ~ .form-check-label,
.radio_container > span input[type=email].error ~ .form-check-label,
.radio_container > span input[type=email].error ~ label,
.checkbox_container > span input[type=email].error ~ .form-check-label,
.checkbox_container > span input[type=email].error ~ label,
input.form-check-input[type=url].error ~ .form-check-label,
.radio_container > span input[type=url].error ~ .form-check-label,
.radio_container > span input[type=url].error ~ label,
.checkbox_container > span input[type=url].error ~ .form-check-label,
.checkbox_container > span input[type=url].error ~ label,
input.form-check-input[type=search].error ~ .form-check-label,
.radio_container > span input[type=search].error ~ .form-check-label,
.radio_container > span input[type=search].error ~ label,
.checkbox_container > span input[type=search].error ~ .form-check-label,
.checkbox_container > span input[type=search].error ~ label,
input.form-check-input[type=tel].error ~ .form-check-label,
.radio_container > span input[type=tel].error ~ .form-check-label,
.radio_container > span input[type=tel].error ~ label,
.checkbox_container > span input[type=tel].error ~ .form-check-label,
.checkbox_container > span input[type=tel].error ~ label,
input.form-check-input[type=color].error ~ .form-check-label,
.radio_container > span input[type=color].error ~ .form-check-label,
.radio_container > span input[type=color].error ~ label,
.checkbox_container > span input[type=color].error ~ .form-check-label,
.checkbox_container > span input[type=color].error ~ label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback, .form-check-inline .radio_container > span input ~ .invalid-feedback, .radio_container > span .form-check-inline input ~ .invalid-feedback,
.form-check-inline .checkbox_container > span input ~ .invalid-feedback,
.checkbox_container > span .form-check-inline input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > select:not(:focus):invalid,
.was-validated .input-group > textarea:not(:focus):invalid,
.was-validated .input-group > input[type=text]:not(:focus):invalid,
.was-validated .input-group > input[type=password]:not(:focus):invalid,
.was-validated .input-group > input[type=datetime]:not(:focus):invalid,
.was-validated .input-group > input[type=datetime-local]:not(:focus):invalid,
.was-validated .input-group > input[type=date]:not(:focus):invalid,
.was-validated .input-group > input[type=month]:not(:focus):invalid,
.was-validated .input-group > input[type=time]:not(:focus):invalid,
.was-validated .input-group > input[type=week]:not(:focus):invalid,
.was-validated .input-group > input[type=number]:not(:focus):invalid,
.was-validated .input-group > input[type=email]:not(:focus):invalid,
.was-validated .input-group > input[type=url]:not(:focus):invalid,
.was-validated .input-group > input[type=search]:not(:focus):invalid,
.was-validated .input-group > input[type=tel]:not(:focus):invalid,
.was-validated .input-group > input[type=color]:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .input-group > select:not(:focus).is-invalid, .input-group > select.error:not(:focus),
.input-group > textarea:not(:focus).is-invalid,
.input-group > textarea.error:not(:focus),
.input-group > input[type=text]:not(:focus).is-invalid,
.input-group > input[type=text].error:not(:focus),
.input-group > input[type=password]:not(:focus).is-invalid,
.input-group > input[type=password].error:not(:focus),
.input-group > input[type=datetime]:not(:focus).is-invalid,
.input-group > input[type=datetime].error:not(:focus),
.input-group > input[type=datetime-local]:not(:focus).is-invalid,
.input-group > input[type=datetime-local].error:not(:focus),
.input-group > input[type=date]:not(:focus).is-invalid,
.input-group > input[type=date].error:not(:focus),
.input-group > input[type=month]:not(:focus).is-invalid,
.input-group > input[type=month].error:not(:focus),
.input-group > input[type=time]:not(:focus).is-invalid,
.input-group > input[type=time].error:not(:focus),
.input-group > input[type=week]:not(:focus).is-invalid,
.input-group > input[type=week].error:not(:focus),
.input-group > input[type=number]:not(:focus).is-invalid,
.input-group > input[type=number].error:not(:focus),
.input-group > input[type=email]:not(:focus).is-invalid,
.input-group > input[type=email].error:not(:focus),
.input-group > input[type=url]:not(:focus).is-invalid,
.input-group > input[type=url].error:not(:focus),
.input-group > input[type=search]:not(:focus).is-invalid,
.input-group > input[type=search].error:not(:focus),
.input-group > input[type=tel]:not(:focus).is-invalid,
.input-group > input[type=tel].error:not(:focus),
.input-group > input[type=color]:not(:focus).is-invalid,
.input-group > input[type=color].error:not(:focus),
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
.input-group > select.form-floating.error:not(:focus-within),
.input-group > textarea.form-floating.error:not(:focus-within),
.input-group > input.form-floating[type=text].error:not(:focus-within),
.input-group > input.form-floating[type=password].error:not(:focus-within),
.input-group > input.form-floating[type=datetime].error:not(:focus-within),
.input-group > input.form-floating[type=datetime-local].error:not(:focus-within),
.input-group > input.form-floating[type=date].error:not(:focus-within),
.input-group > input.form-floating[type=month].error:not(:focus-within),
.input-group > input.form-floating[type=time].error:not(:focus-within),
.input-group > input.form-floating[type=week].error:not(:focus-within),
.input-group > input.form-floating[type=number].error:not(:focus-within),
.input-group > input.form-floating[type=email].error:not(:focus-within),
.input-group > input.form-floating[type=url].error:not(:focus-within),
.input-group > input.form-floating[type=search].error:not(:focus-within),
.input-group > input.form-floating[type=tel].error:not(:focus-within),
.input-group > input.form-floating[type=color].error:not(:focus-within) {
  z-index: 4;
}

.alert, .ce_form form .ajaxconfirm {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success, .ce_form form .ajaxconfirm {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: 800;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(22, 60, 96, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #163c60;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* Vendor – IcoMoon fonts */
@font-face {
  font-family: "icomoon";
  src: url("../icons/fonts/icomoon.ttf?py70qe") format("truetype"), url("../icons/fonts/icomoon.woff?py70qe") format("woff"), url("../icons/fonts/icomoon.svg?py70qe#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check-circle:before {
  content: "\e908";
}

.icon-instagram:before {
  content: "\e906";
}

.icon-tiktok:before {
  content: "\e907";
}

.icon-search:before {
  content: "\e900";
}

.icon-zoom:before {
  content: "\e900";
}

.icon-download:before {
  content: "\e901";
}

.icon-chevron-right:before {
  content: "\e902";
}

.icon-chevron-left:before {
  content: "\e903";
}

.icon-chevron-down:before {
  content: "\e904";
}

.icon-chevron-up:before {
  content: "\e905";
}

/* Vendor – fontsource */
/* montserrat-cyrillic-ext-wght-normal */
@font-face {
  font-family: "Montserrat Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(./files/montserrat-cyrillic-ext-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* montserrat-cyrillic-wght-normal */
@font-face {
  font-family: "Montserrat Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(./files/montserrat-cyrillic-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* montserrat-vietnamese-wght-normal */
@font-face {
  font-family: "Montserrat Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(./files/montserrat-vietnamese-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* montserrat-latin-ext-wght-normal */
@font-face {
  font-family: "Montserrat Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(./files/montserrat-latin-ext-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* montserrat-latin-wght-normal */
@font-face {
  font-family: "Montserrat Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(./files/montserrat-latin-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Base */
/* Helper class */
/* Reset style */
.reset {
  margin: 0;
  padding: 0;
  list-style: none;
  background: transparent;
  border: 0;
}

/* Links */
a {
  transition: color 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  a {
    transition: none;
  }
}

/* Text */
.tab-panel .tab-content, .jobs-list-item__content-title, .job-intro__body-content-text, .team-text__text, .ce_accordion {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.jobs-list-item__content, .header-navigation {
  overflow: auto;
}
.jobs-list-item__content::-webkit-scrollbar, .header-navigation::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(36.2627118644, 98.8983050847, 158.2372881356);
}
.jobs-list-item__content::-webkit-scrollbar-track, .header-navigation::-webkit-scrollbar-track {
  background-color: rgb(36.2627118644, 98.8983050847, 158.2372881356);
  box-shadow: inset 0 0 0 1px rgb(36.2627118644, 98.8983050847, 158.2372881356);
}
.jobs-list-item__content::-webkit-scrollbar-thumb, .header-navigation::-webkit-scrollbar-thumb {
  background: #000;
  border: solid rgb(36.2627118644, 98.8983050847, 158.2372881356);
  border-width: 0 1px;
}

/* Reset */
select,
input[type=checkbox],
input[type=checkbox] + label,
input[type=radio],
input[type=radio] + label {
  cursor: pointer;
}

select[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  width: 100%;
}
table td, table th {
  padding: 6px;
}

ul ul {
  margin-bottom: 0;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/* Base */
/* Vertical rhythm */
ul, ol, dd,
p, figure,
pre, table, fieldset {
  margin-top: 0;
  margin-bottom: 1.3333333333rem;
}

hr {
  margin-top: 1.3333333333rem;
  margin-bottom: 1.3333333333rem;
}

img[sizes] {
  width: auto;
}

*[class^=ce_], *[class^=mod_] {
  margin-bottom: var(--content-element-margin);
  margin-top: 0;
}

.mod_article {
  margin-bottom: var(--article-margin);
}
.mod_article:last-child, .mod_article:not(.section--simple-content) {
  margin-bottom: 0;
}

.layout-header *[class^=mod_],
.layout-footer *[class^=mod_] {
  margin-bottom: 0;
}

/* Grid */
.mod_article .row .ce_column {
  margin-bottom: 0;
}
.mod_article .row .ce_column > *[class^=ce_],
.mod_article .row .ce_column > *[class^=mod_] {
  padding-right: 0;
  padding-left: 0;
}

/* Float classes */
.float_left {
  float: left;
}
.float_left.image_container {
  margin-right: 1.3333333333rem;
  margin-bottom: 1.3333333333rem;
}
@media (max-width: 575.98px) {
  .float_left.image_container {
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .float_left {
    float: none;
  }
}

.float_right {
  float: right;
}
.float_right.image_container {
  margin-bottom: 1.3333333333rem;
  margin-left: 1.3333333333rem;
}
@media (max-width: 575.98px) {
  .float_right.image_container {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .float_right {
    float: none;
  }
}

.ce_text::after {
  display: block;
  clear: both;
  content: "";
}

/* Hide invisible elements */
.invisible {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
}

/* Typography */
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 18px;
  }
}

/* Form */
.formbody .widget {
  margin-bottom: 0.6666666667rem;
}
.formbody .widget > label,
.formbody .widget > fieldset > legend {
  display: block;
  margin-bottom: 0.2rem;
  font-weight: bold;
}
.formbody .widget > label span.mandatory,
.formbody .widget > fieldset > legend span.mandatory {
  margin-left: 2px;
  color: #dc3545;
}
.formbody p.error {
  margin-bottom: 0.2rem;
  color: #dc3545;
  font-weight: bold;
}
.formbody .radio_container,
.formbody .checkbox_container {
  margin: 0;
  padding: 0;
  border: 0;
}
.formbody .radio_container > span,
.formbody .checkbox_container > span {
  display: block;
}
.formbody .captcha {
  width: 75px;
  margin-right: 8px;
  text-align: center;
}
.formbody .captcha_text {
  font-weight: bold;
}
/* Ajax form */
.ce_form form {
  transition: opacity 0.3s;
}
.ce_form form.ajax-loading {
  cursor: progress;
  opacity: 0.5;
}
.ce_form form.ajax-loading .formbody {
  pointer-events: none;
}
.ce_form form .ajaxconfirm *:last-child {
  margin-bottom: 0;
}

/* Checkbox / Radio field */
/* Layout */
/* Header */
.header-container {
  padding-top: var(--header-padding-y);
  padding-bottom: var(--header-padding-y);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  pointer-events: none;
}
.header-container__inside, .header-container__inside-copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.header-container__inside {
  position: relative;
  z-index: 1;
}
.header-container__inside > .menu-btn {
  pointer-events: auto;
}
.header-container__inside-copy {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 10;
  --header-white-clip-top: calc(var(--header-clip-top, 0.5) * 100%);
  --header-white-clip-bottom: calc(var(--header-clip-bottom, 0.5) * 100%);
  clip-path: polygon(0% var(--header-white-clip-top), 100% var(--header-white-clip-top), 100% var(--header-white-clip-bottom), 0% var(--header-white-clip-bottom));
}
.header-container__inside-copy .header-logo {
  filter: brightness(0) invert(1);
}
.header-container__inside-copy .menu-btn::before, .header-container__inside-copy .menu-btn::after, .header-container__inside-copy .menu-btn__text {
  filter: brightness(0) invert(1);
}

.header-logo {
  height: var(--header-height);
  z-index: 2;
  position: relative;
  pointer-events: auto;
  width: fit-content;
}
.header-logo img {
  display: block;
  height: 100%;
  width: auto;
}

.header-navigation {
  margin-top: auto;
}
.header-navigation nav {
  font-size: min(clamp(25px, 5vw, 55px), 5vh);
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
.header-navigation nav ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.header-navigation nav ul.level_1 > li {
  margin-bottom: 0.35em;
}
.header-navigation nav ul.level_1 > li:last-child {
  margin-bottom: 0;
}
.header-navigation nav ul.level_2 {
  text-transform: none;
  font-size: 0.65em;
  font-weight: 400;
}
.header-navigation nav a {
  color: #fff;
  text-decoration: none;
}
.header-navigation nav a:focus, .header-navigation nav a:visited {
  color: #fff;
}
.header-navigation nav a:hover {
  color: #70e788;
}
.header-navigation nav a:hover, .header-navigation nav a:focus, .header-navigation nav a:active {
  text-decoration: none;
}
.header-navigation nav strong {
  font-weight: inherit;
  color: #70e788;
}

/* Footer */
html {
  position: relative;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.layout-wrapper > *:not(.layout-container) {
  flex-shrink: 0;
}

.layout-container {
  flex: 1 1 auto;
}

.layout-footer {
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.footer-container {
  padding-top: calc(1.3472222213rem + 1.3125000013vw);
  padding-bottom: calc(1.3472222213rem + 1.3125000013vw);
  border-top: 1px solid #163c60;
}
@media (min-width: 1200px) {
  .footer-container {
    padding-top: 2.2222222222rem;
  }
}
@media (min-width: 1200px) {
  .footer-container {
    padding-bottom: 2.2222222222rem;
  }
}
@media (min-width: 768px) {
  .footer-container {
    --bs-gutter-x: 1.5rem;
    display: flex;
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
    justify-content: space-between;
  }
  .footer-container > * {
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
  }
}

@media (max-width: 767.98px) {
  .footer-intro {
    --bs-gutter-x: 1.5rem;
    display: flex;
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-bottom: 2.5rem;
  }
  .footer-intro > * {
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
  }
}

.footer-visual {
  font-size: 0.6111111111rem;
  display: flex;
}

.footer-logo {
  font-size: clamp(50px, 6vw, 72px);
  line-height: 1;
}
.footer-logo a {
  display: flex;
}
.footer-logo img {
  max-width: 1em;
  margin-right: 0.45em;
}

.footer-text {
  max-width: 14.75em;
  line-height: 1.6;
  font-weight: 600;
}

.footer-content {
  font-size: 0.6111111111rem;
  letter-spacing: 0.01em;
}
@media (min-width: 768px) {
  .footer-content .social-links {
    margin-left: auto;
  }
}

.footer-socials {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .footer-socials {
    margin-bottom: 0.75rem;
  }
}

.footer-action {
  font-size: 11px;
}
@media (min-width: 768px) {
  .footer-action {
    --bs-gutter-x: 1.75rem;
    display: flex;
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
  .footer-action > * {
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
  }
}

.footer-navigation nav {
  margin-bottom: 0;
}
.footer-navigation strong {
  font-weight: inherit;
  text-shadow: 0 0 0.075em currentColor, 0 0 0.075em currentColor;
}
.footer-navigation ul {
  padding-left: 0;
  list-style: none;
  padding-left: 0;
  list-style: none;
  --bs-gutter-x: 2rem;
  display: flex;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.footer-navigation ul > * {
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
}
.footer-navigation ul li {
  position: relative;
}
.footer-navigation ul li:not(:last-child)::after {
  position: absolute;
  top: 0.4em;
  bottom: 0.4em;
  right: 0;
  width: 1px;
  background-color: currentColor;
  content: "";
}
.footer-navigation ul li a {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.6s cubic-bezier(0.19, 1, 0.22, 1), color 0.3s;
}
.footer-navigation ul li a:hover {
  animation: linkUnderlineVisibleAnimate 0.3s;
}
@media (max-width: 767.98px) {
  .footer-navigation ul {
    justify-content: center;
  }
}

.coming-soon__footer .footer-copyright {
  margin-bottom: 0.5rem;
}
@media (max-width: 767.98px) {
  .footer-copyright {
    text-align: center;
  }
}

/* Content */
[data-animation-gradient-text] {
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
[data-animation-gradient-text] .char {
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

[data-animation-horizontal] {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
}

[data-animation=text] {
  transform: translateY(10%);
  opacity: 0;
}

[data-animation=image-slide-down] img {
  clip-path: inset(0 0 100% 0);
}

.intro-text-wrapper {
  top: 0;
  width: 100%;
}

html .glightbox-clean .gslide-description {
  background: #70e788;
}
html .glightbox-clean .gslide-title {
  font-size: 1rem;
  font-family: "Montserrat Variable", Open Sans, sans-serif;
  color: #163c60;
  text-transform: none;
}
html .glightbox-clean .gslide-title:last-child {
  margin-bottom: 0;
}
html .goverlay {
  background: rgba(5, 34, 61, 0.95) !important;
}
@media (max-width: 768.98px) {
  html .glightbox-clean .gdesc-inner {
    padding: 0;
  }
  html .glightbox-mobile .glightbox-container .gslide-description {
    padding-bottom: 10px;
  }
}

/* Miscellaneous */
:root {
  --scrollbar-width: 0px;
  --banner-height: max(100dvh, 20vw);
  --header-height: clamp(50px, 7vw, 102px);
  --header-padding-y: clamp(30px, 5vw, 60px);
  --container-padding-x: 40px;
  --container-margin-top: 1.3333333333rem;
  --container-margin-bottom: 1.3333333333rem;
  --article-margin: 9.75rem;
  --content-element-margin: 3rem;
  --header-offset-y: calc(var(--header-height) + (var(--header-padding-y) * 2));
}
@media (min-width: 768px) {
  :root {
    --container-padding-x: clamp(60px, 10vw, 120px);
  }
}
@media (min-width: 768px) {
  :root {
    --container-margin-top: 2rem;
  }
}
@media (min-width: 992px) {
  :root {
    --container-margin-top: 2.6666666667rem;
  }
}
@media (min-width: 768px) {
  :root {
    --container-margin-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  :root {
    --container-margin-bottom: 2.6666666667rem;
  }
}
@media (min-width: 768px) {
  :root {
    --article-margin: 4rem;
  }
}
@media (min-width: 992px) {
  :root {
    --article-margin: 10.5rem;
  }
}
@media (min-width: 768px) {
  :root {
    --content-element-margin: 2rem;
  }
}
@media (min-width: 992px) {
  :root {
    --content-element-margin: 2.6666666667rem;
  }
}

body.container-reset {
  background-color: #70e788;
}

html,
body {
  overflow-x: hidden;
}

.layout-container {
  position: relative;
  z-index: 1;
}

.ce_text a {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.6s cubic-bezier(0.19, 1, 0.22, 1), color 0.3s;
}
.ce_text a:hover {
  animation: linkUnderlineVisibleAnimate 0.3s;
}

.btn, .link-more-btn a, .formbody .submit {
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.link-more-btn {
  text-align: center;
  padding-top: calc(1.2916666658rem + 0.5625000013vw);
}
@media (min-width: 1200px) {
  .link-more-btn {
    padding-top: 1.6666666667rem;
  }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
  color: var(--headings-color, #163c60);
}

.section-headline {
  margin-bottom: var(--content-element-margin);
}
.section-headline--center {
  text-align: center;
}
.section-headline--center-limit-width {
  text-align: center;
  max-width: 49em;
  margin-inline: auto;
  margin-bottom: calc(1.5027777769rem + 3.4125000013vw);
}
@media (min-width: 1200px) {
  .section-headline--center-limit-width {
    margin-bottom: 3.7777777778rem;
  }
}
.section-headline--center h1, .section-headline--center .h1, .section-headline--center-limit-width h1, .section-headline--center-limit-width .h1 {
  font-size: clamp(50px, 5vw, 56px);
}
@media (max-width: 410px) {
  .section-headline--center h1, .section-headline--center .h1, .section-headline--center-limit-width h1, .section-headline--center-limit-width .h1 {
    font-size: 12vw;
  }
}

h2, .h2 {
  font-size: clamp(50px, 5vw, 56px);
}

h1, .h1 {
  line-height: 1;
}

.ce_gallery .image_container a[data-lightbox],
.ce_image .image_container a[data-lightbox],
.ce_text .image_container a[data-lightbox] {
  position: relative;
  display: inline-block;
  max-width: 100%;
  text-decoration: none;
  vertical-align: top;
}
.ce_gallery .image_container a[data-lightbox]::after,
.ce_image .image_container a[data-lightbox]::after,
.ce_text .image_container a[data-lightbox]::after {
  font-weight: normal;
  font-family: "icomoon" !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e900";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ce_gallery .image_container a[data-lightbox] img,
.ce_image .image_container a[data-lightbox] img,
.ce_text .image_container a[data-lightbox] img {
  display: block;
}
.ce_gallery .image_container a[data-lightbox]::before,
.ce_image .image_container a[data-lightbox]::before,
.ce_text .image_container a[data-lightbox]::before {
  transition: opacity 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  content: "";
  mix-blend-mode: multiply;
}
@media (prefers-reduced-motion: reduce) {
  .ce_gallery .image_container a[data-lightbox]::before,
  .ce_image .image_container a[data-lightbox]::before,
  .ce_text .image_container a[data-lightbox]::before {
    transition: none;
  }
}
.ce_gallery .image_container a[data-lightbox]::after,
.ce_image .image_container a[data-lightbox]::after,
.ce_text .image_container a[data-lightbox]::after {
  transition: transform 0.3s, opacity 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  padding: 0.8rem;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  background-color: #163c60;
  border-radius: 0;
  transform: translate(-50%, 50%);
  opacity: 0;
}
@media (prefers-reduced-motion: reduce) {
  .ce_gallery .image_container a[data-lightbox]::after,
  .ce_image .image_container a[data-lightbox]::after,
  .ce_text .image_container a[data-lightbox]::after {
    transition: none;
  }
}
.ce_gallery .image_container a[data-lightbox]:hover::before,
.ce_image .image_container a[data-lightbox]:hover::before,
.ce_text .image_container a[data-lightbox]:hover::before {
  opacity: 1;
}
.ce_gallery .image_container a[data-lightbox]:hover::after,
.ce_image .image_container a[data-lightbox]:hover::after,
.ce_text .image_container a[data-lightbox]:hover::after {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.ce_gallery .image_container::after,
.ce_image .image_container::after,
.ce_text .image_container::after {
  display: block;
  clear: both;
  content: "";
}
.caption {
  font-size: 0.9em;
  color: var(--bs-secondary-color);
  font-style: italic;
}

.list-check ul,
.accordion ul,
.tab-content ul {
  padding-left: 0;
  list-style: none;
  letter-spacing: 0.001em;
}
.list-check ul li,
.accordion ul li,
.tab-content ul li {
  margin-bottom: 0.75rem;
  position: relative;
  padding-left: 1.75em;
}
.list-check ul li::before,
.accordion ul li::before,
.tab-content ul li::before {
  font-weight: normal;
  font-family: "icomoon" !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e908";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.list-check ul li::before,
.accordion ul li::before,
.tab-content ul li::before {
  position: absolute;
  top: 0.3em;
  left: 0;
  font-weight: 600;
}
.list-check ul li strong,
.accordion ul li strong,
.tab-content ul li strong {
  font-weight: 600;
}

.list-check ul li:last-child,
.accordion ul li:last-child,
.tab-content ul li:last-child {
  margin-bottom: 0;
}

.list-check ul {
  padding-left: 2rem;
}

.marquee {
  --offset: 0px;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  --animation-display: 60s;
  position: relative;
  overflow: hidden;
  padding-top: 0.1em;
  margin-top: -0.1em;
  white-space: nowrap;
}
.job-intro .marquee {
  --offset: 25%;
  --animation-display: 30s;
}

.marquee__inner {
  position: relative;
  display: flex;
  width: fit-content;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee var(--animation-display) linear infinite;
}
.marquee__inner--inverted {
  animation: marquee-inverted var(--animation-display) linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }
  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
@keyframes marquee-inverted {
  0% {
    transform: translate3d(var(--move-final), 0, 0);
  }
  100% {
    transform: translate3d(var(--move-initial), 0, 0);
  }
}
.section--simple-content {
  margin-bottom: var(--article-margin);
}
.section--simple-content:first-child {
  margin-top: var(--header-offset-y);
}
.section--simple-content:last-child {
  margin-bottom: var(--container-margin-bottom);
}

body.page-bg-triangle {
  background-color: #5d9ce8;
  --bg-triangle-size: min(60vh, 70vw);
  --bg-offset-top: min(40vh, 15vw);
  --bg-offset-top-min: clamp(100px, 10vw, 400px);
}
body.page-bg-triangle .layout-footer {
  background-color: transparent;
}
body.page-bg-triangle .layout-wrapper::before {
  content: "";
  position: absolute;
  top: calc(var(--bg-offset-top) + var(--bg-offset-top-min));
  background-color: #70e788;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  clip-path: polygon(0 var(--bg-triangle-size), 100% 0, 100% 100%, 0% 100%);
}
body.page-bg-triangle .layout-container {
  padding-bottom: calc(1.513888888rem + 3.5625000013vw);
}
@media (min-width: 1200px) {
  body.page-bg-triangle .layout-container {
    padding-bottom: 3.8888888889rem;
  }
}

.video_container iframe {
  max-width: 100%;
  vertical-align: top;
  border: 0;
}
.video_container video {
  max-width: 100%;
  height: auto;
}

.ratio,
.responsive {
  position: relative;
  width: 100%;
}
.ratio::before,
.responsive::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > *,
.responsive > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-43 {
  --bs-aspect-ratio: 75%;
}

.ratio-3x2 {
  --bs-aspect-ratio: 66.6666666667%;
}

.ratio-32 {
  --bs-aspect-ratio: 66.6666666667%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-169 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-16x10 {
  --bs-aspect-ratio: 62.5%;
}

.ratio-1610 {
  --bs-aspect-ratio: 62.5%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.ratio-219 {
  --bs-aspect-ratio: 42.8571428571%;
}

.youtube-center, .job-details .ce_youtube {
  max-width: 796px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.mouse {
  --size: calc(1.3583333324rem + 1.4625000013vw);
  --nudgeMouse: calc(var(--size) * 0.215);
  width: var(--size);
  height: calc(var(--size) * 2);
  animation: nudgeMouse 5.5s ease-out infinite;
  position: relative;
  font-size: var(--size);
}
@media (min-width: 1200px) {
  .mouse {
    --size: 2.3333333333rem;
  }
}
.mouse:after {
  font-size: calc(var(--size) * 2.75);
  content: "";
  background-color: #163c60;
  width: calc(var(--size) * 0.165);
  height: calc(var(--size) * 0.165);
  border-radius: 100%;
  animation: trackBallSlide 5.5s linear infinite;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.mouse__frame {
  width: 100%;
  position: absolute;
  z-index: 1;
}
.mouse__frame path {
  fill: none;
  stroke: #ffffff;
  stroke-width: 3;
  opacity: 0.5;
}
.mouse__left, .mouse__right {
  width: 50%;
  position: absolute;
  z-index: 2;
}
.mouse__left path, .mouse__right path {
  fill: none;
  stroke: #163c60;
  stroke-width: 3;
  fill-opacity: 1;
}
.mouse__left {
  animation: DrawLine 5.5s ease-out infinite;
}
.mouse__right {
  right: 0;
  animation: DrawLineBack 5.5s ease-out infinite;
}

/*Keyframes*/
@keyframes DrawLine {
  0% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  22% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  38% {
    stroke-dashOffset: 125;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  38.1% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 0;
  }
  48% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  64% {
    stroke-dashOffset: 125;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  64.1% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 0;
  }
  74% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  90% {
    stroke-opacity: 1;
    stroke-dashOffset: 125;
    stroke-dasharray: 120;
  }
  90.1% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 0;
  }
}
@keyframes DrawLineBack {
  0% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  22% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  38% {
    stroke-dashOffset: -114;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  38.1% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 0;
  }
  48% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  64% {
    stroke-dashOffset: -114;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  64.1% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 0;
  }
  74% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 1;
  }
  90% {
    stroke-opacity: 1;
    stroke-dashOffset: -114;
    stroke-dasharray: 120;
  }
  90.1% {
    stroke-dashOffset: 0;
    stroke-dasharray: 120;
    stroke-opacity: 0;
  }
}
@keyframes nudgeMouse {
  0% {
    transform: translateY(0);
  }
  22% {
    transform: translateY(0px);
  }
  38% {
    transform: translateY(var(--nudgeMouse));
  }
  48% {
    transform: translateY(0px);
  }
  64% {
    transform: translateY(var(--nudgeMouse));
  }
  74% {
    transform: translateY(var(--nudgeMouse));
  }
  90% {
    transform: translateY(var(--nudgeMouse));
  }
}
/*Mouse wheel*/
@keyframes trackBallSlide {
  0% {
    transform: translateY(0.2em) scale(1);
    opacity: 1;
  }
  22% {
    transform: translateY(0.2em) scale(1);
    opacity: 1;
  }
  26% {
    transform: translateY(0.3em) scale(0.9);
    opacity: 1;
  }
  34% {
    transform: translateY(0.55em) scale(0.1);
    opacity: 0;
  }
  41% {
    transform: translateY(0.3em) scale(0);
    opacity: 0.3;
  }
  48% {
    transform: translateY(0.2em) scale(1);
    opacity: 1;
  }
  52% {
    transform: translateY(0.3em) scale(0.9);
    opacity: 1;
  }
  60% {
    transform: translateY(0.55em) scale(0.1);
    opacity: 0;
  }
  67% {
    transform: translateY(0.3em) scale(0);
    opacity: 0.3;
  }
  74% {
    transform: translateY(0.2em) scale(1);
    opacity: 1;
  }
  78% {
    transform: translateY(0.3em) scale(0.9);
    opacity: 1;
  }
  86% {
    transform: translateY(0.55em) scale(0.1);
    opacity: 0;
  }
  93% {
    transform: translateY(0.3em) scale(0);
    opacity: 0.3;
  }
  100% {
    transform: translateY(0.2em) scale(1);
    opacity: 1;
  }
}
.social-links {
  font-size: 45px;
  line-height: 1;
}
.social-links__list {
  --social-links-gap: 0.6em;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  --bs-gutter-x: var(--social-links-gap);
  display: flex;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.social-links__list > * {
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
}
.social-links__list li a {
  color: #163c60;
  text-decoration: none;
}
.social-links__list li a:focus, .social-links__list li a:visited {
  color: #163c60;
}
.social-links__list li a:hover {
  color: rgb(36.2627118644, 98.8983050847, 158.2372881356);
}
.social-links__list li a:hover, .social-links__list li a:focus, .social-links__list li a:active {
  text-decoration: none;
}

/* Accordion */
.ce_accordion {
  font-size: 18px;
  letter-spacing: 0.01em;
}
.ce_accordion .toggler {
  display: flex;
  align-items: center;
  min-height: calc(3.25 * 1.6666666667 * 1em);
  width: 100%;
  padding: 18px 28px;
  border: 0.1666666667rem solid #163c60;
  font-weight: 800;
  color: var(--bs-body-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .ce_accordion .toggler {
    transition: none;
  }
}
.ce_accordion .accordion {
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
}
.ce_accordion .accordion > div {
  padding: 28px 28px;
  color: var(--bs-body-color);
}
.ce_accordion .accordion > div > *:last-child {
  margin-bottom: 0;
}
.ce_accordion.active .toggler {
  background-color: #fff;
}
.ce_accordion + .ce_accordion {
  margin-top: calc(var(--content-element-margin) * -1 + 1.25rem);
}
@media (max-width: 767.98px) {
  .ce_accordion {
    font-size: 24px;
  }
}

.banner-wrapper {
  margin-bottom: 0;
}

.banner {
  padding-top: calc(1.4583333324rem + 2.8125000013vw);
  padding-bottom: calc(1.4583333324rem + 2.8125000013vw);
  height: var(--banner-height);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #70e788;
}
@media (min-width: 1200px) {
  .banner {
    padding-top: 3.3333333333rem;
  }
}
@media (min-width: 1200px) {
  .banner {
    padding-bottom: 3.3333333333rem;
  }
}
.banner__visual {
  text-align: center;
  margin-left: calc(var(--container-padding-x) * -0.5);
  margin-right: calc(var(--container-padding-x) * -0.5);
  height: 100%;
}
.banner__visual figure {
  margin-bottom: 0;
}
.banner__visual figure,
.banner__visual img {
  height: 100%;
}
.banner__scroll {
  position: absolute;
  bottom: 1rem;
}
@media (min-width: 992px) {
  .banner__visual-inside {
    margin-right: 0;
    margin-left: 0;
  }
}

/* Download */
.ce_download + .ce_download > .download-element:first-child {
  margin-top: calc(var(--content-element-margin) * -1);
}

.ce_downloads ul {
  padding-left: 0;
  list-style: none;
}

.download-element a {
  color: inherit;
  text-decoration: none;
  display: flex;
  width: fit-content;
}
.download-element a:focus, .download-element a:visited {
  color: inherit;
}
.download-element a:hover {
  color: rgb(36.2627118644, 98.8983050847, 158.2372881356);
}
.download-element a:hover, .download-element a:focus, .download-element a:active {
  text-decoration: none;
}
.download-element a::before {
  font-weight: normal;
  font-family: "icomoon" !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e901";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.download-element a::before {
  position: relative;
  margin-right: 0.8em;
  font-size: 0.9em;
  line-height: inherit;
  opacity: 0.8;
  top: 0.125em;
}
.download-element .size {
  position: relative;
  top: 0.3333333333em;
  padding-left: 0.8em;
  font-size: 0.8em;
  white-space: nowrap;
  opacity: 0.8;
}

.intro-text-wrapper {
  color: #fff;
  padding-top: calc(var(--header-padding-y) + var(--header-height));
  padding-bottom: calc(var(--header-padding-y) + var(--header-height));
  background-color: #ff5f3d;
  margin-bottom: 0;
  min-height: var(--banner-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  will-change: transform;
}

.intro-text {
  hyphens: auto;
  hyphenate-limit-chars: 8 3 4;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: clamp(100px, 12vw, 9rem);
  line-height: 1;
  font-weight: 500;
}
.intro-text > *:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .intro-text {
    font-weight: 600;
    line-height: 1.1194029851;
    max-width: 7.9em;
  }
}
@media (min-width: 1200px) {
  .intro-text {
    font-size: 9rem;
  }
}

.team-text-wrapper {
  --headings-color: #fff;
  --headings-font-weight: $font-weight-normal;
  padding-top: calc(1.2916666658rem + 0.5625000013vw);
  padding-bottom: calc(1.2916666658rem + 0.5625000013vw);
  background-color: #193754;
  color: #fff;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .team-text-wrapper {
    padding-top: 1.6666666667rem;
  }
}
@media (min-width: 1200px) {
  .team-text-wrapper {
    padding-bottom: 1.6666666667rem;
  }
}

.team-text {
  font-size: 16px;
  letter-spacing: 0.01em;
}
.team-text__intro {
  position: relative;
  left: 50%;
  width: calc(100vw - var(--scrollbar-width));
  transform: translateX(-50%);
  margin-bottom: 2.1em;
  font-size: 3.75em;
}
.team-text__intro::after {
  --icon-size: 3.6em;
  position: absolute;
  width: var(--icon-size);
  height: var(--icon-size);
  left: -1em;
  top: calc(100% - 0.45em);
  content: "";
  background-image: url("../../../images/check-circle.svg");
  background-repeat: no-repeat;
  z-index: -1;
}
.team-text__headline {
  margin-bottom: 0;
  font-size: 1em;
  text-transform: uppercase;
  white-space: nowrap;
}
.team-text__text {
  margin-left: auto;
  max-width: 15.5em;
}
.team-text__text > *:last-child {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .team-text__intro::after {
    left: calc(50% - var(--icon-size));
  }
}
@media (min-width: 768px) {
  .team-text {
    font-size: 1rem;
  }
  .team-text__intro {
    font-size: 7em;
    margin-bottom: 0.4em;
  }
  .team-text__intro::after {
    --icon-size: 2em;
    left: calc(50% - var(--icon-size));
    top: calc(100% - 0.75em);
  }
  .team-text__text {
    max-width: 32.8em;
  }
}
@media (min-width: 1200px) {
  .team-text__intro {
    font-size: 10em;
  }
}

/* Gallery */
.ce_gallery .ce_gallery > ul li.col_first {
  clear: none;
}
.ce_gallery ul {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  --bs-gutter-y: var(--bs-gutter-x);
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.ce_gallery li {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
  flex: 0 0 auto;
  width: 50%;
}
.ce_gallery li figure {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_1 li {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_2 li {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_3 li {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_4 li {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_5 li {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_6 li {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_7 li {
    flex: 0 0 auto;
    width: 14.28571429%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_8 li {
    flex: 0 0 auto;
    width: 12.5%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_9 li {
    flex: 0 0 auto;
    width: 11.11111111%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_10 li {
    flex: 0 0 auto;
    width: 10%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_11 li {
    flex: 0 0 auto;
    width: 9.09090909%;
  }
}
@media (min-width: 768px) {
  .ce_gallery ul.cols_12 li {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}

.job-list-widget {
  padding-top: calc(var(--article-margin) * 1.2);
  padding-bottom: var(--article-margin);
  min-height: 100vh;
  background-image: url("../../../images/bg-text-deco.svg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.job-list-widget__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.job-list-widget__content {
  background-color: #f8f9fa;
  width: 100%;
  flex-grow: 1;
  display: flex;
}
.job-list-widget__content iframe {
  width: 100%;
  min-height: 500px;
}

.job-reader {
  position: relative;
  --section-margin: calc(var(--article-margin) * 0.7);
  margin-bottom: 0;
}
.job-reader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #459eef;
  z-index: -2;
}

.job-intro {
  padding-bottom: calc(var(--article-margin) * 0.5);
  padding-top: var(--header-offset-y);
  position: relative;
  z-index: 1;
}
.job-intro__title {
  --headings-color: #fff;
  --headings-font-weight: $font-weight-normal;
  letter-spacing: 0.01em;
  font-size: calc(1.9583333324rem + 9.5625000013vw);
  margin-bottom: calc(1.4583333324rem + 2.8125000013vw);
  margin-bottom: 1em;
  line-height: 1;
}
@media (min-width: 1200px) {
  .job-intro__title {
    font-size: 8.3333333333rem;
  }
}
@media (min-width: 1200px) {
  .job-intro__title {
    margin-bottom: 3.3333333333rem;
  }
}
.job-intro__headline {
  --headings-color: #70e788;
  line-height: 1.1964285714;
  position: relative;
  z-index: 1;
  margin-bottom: -0.6em;
  max-width: 16em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.job-intro__body-visual {
  margin-bottom: var(--content-element-margin);
}
.job-intro__body-content {
  font-size: 24px;
}
.job-intro__body-content-text {
  font-weight: 600;
  letter-spacing: 0.01em;
}
.job-intro__body-content-text > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .job-intro__headline {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .job-intro {
    padding-bottom: calc(var(--section-margin) * 0.5);
  }
  .job-intro__title {
    margin-bottom: 0.4em;
    line-height: 0.9;
  }
  .job-intro__body {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .job-intro__body-visual, .job-intro__body-content {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
    flex: 0 0 auto;
    width: 50%;
  }
  .job-intro__body-visual {
    margin-bottom: 200px;
  }
  .job-intro__body-visual-inside {
    width: calc(50vw - var(--scrollbar-width) / 2);
    position: relative;
    float: right;
  }
  .job-intro__body-visual-inside img {
    width: 100%;
    max-height: 723px;
    object-fit: cover;
  }
  .job-intro__body-content {
    display: flex;
    flex-direction: column;
    padding-left: 2em;
  }
  .job-intro__body-content-text {
    max-width: 17em;
    margin-left: auto;
    margin-top: auto;
  }
}
@media (min-width: 992px) {
  .job-intro__body-content {
    font-size: 30px;
  }
}

.job-details {
  position: relative;
  padding-top: calc(var(--article-margin) * 0.5);
  padding-bottom: var(--article-margin);
  --bg-triangle-size: min(100vh, 70vw);
  --bg-offset-top: min(65vh, 35vw);
}
.job-details::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: -2;
  width: calc(100vw - var(--scrollbar-width));
  background-color: #70e788;
  transform: translateX(-50%);
  content: "";
}
.job-details::after {
  top: var(--bg-offset-top, 0);
}
.job-details::before {
  content: "";
  position: absolute;
  bottom: calc(100% + var(--bg-offset-top, 0) * -1);
  left: 50%;
  z-index: -1;
  width: calc(100vw - var(--scrollbar-width)) !important;
  transform: translateX(-50%);
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 var(--bg-triangle-size) 100vw;
  border-color: transparent transparent #70e788 transparent;
}
.job-details__headline {
  line-height: 1;
  margin-bottom: 1.35em;
}
.job-details .ce_youtube {
  margin-bottom: var(--article-margin);
}
@media (min-width: 768px) {
  .job-details {
    padding-top: calc(var(--section-margin) * 0.5);
  }
  .job-details__headline {
    margin-bottom: 1.85em;
  }
}

.jobs-list-wrapper {
  --content-element-margin: 0;
  background-color: #193754;
  position: relative;
}
.jobs-list-wrapper__content {
  --element-size: clamp(38px, 8vw, 70px);
  position: relative;
  width: 100%;
  padding-top: var(--header-padding-y);
  padding-bottom: var(--header-padding-y);
}
.jobs-list-wrapper__bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.jobs-list-wrapper__bg img {
  max-width: 110%;
  max-height: 100%;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .jobs-list-wrapper__content {
    --element-size: min(clamp(36px, 8vw, 70px), 4.5vh);
    height: 100vh;
  }
  .jobs-list-wrapper__bg img {
    max-width: 70%;
    max-height: 70%;
  }
}

.jobs-list {
  z-index: 1;
  padding-left: calc(var(--container-padding-x) * 0.5);
  padding-right: calc(var(--container-padding-x) * 0.5);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
.jobs-list-item {
  position: relative;
  aspect-ratio: 1;
  background: rgb(36.2627118644, 98.8983050847, 158.2372881356);
  width: 6.25em;
  font-size: var(--element-size);
}
.jobs-list-item__visual figure {
  margin-bottom: 0;
}
.jobs-list-item__visual figure img {
  width: 100%;
}
.jobs-list-item__content {
  transition: opacity 0.3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: rgba(69, 158, 239, 0.9);
  padding: 0.5em 0.3em;
  opacity: 0;
}
@media (prefers-reduced-motion: reduce) {
  .jobs-list-item__content {
    transition: none;
  }
}
.jobs-list-item__content-title {
  font-size: 0.8em;
  line-height: 1.125;
  margin-bottom: 0.5em;
}
.jobs-list-item__content-link {
  display: flex;
  justify-content: center;
}
.jobs-list-item__content-link a {
  font-size: 0.33em;
}
.jobs-list-item:hover {
  z-index: 2;
}
.jobs-list-item:hover .jobs-list-item__content {
  opacity: 1;
}
@media (max-width: 767.98px) {
  .jobs-list-item {
    margin-bottom: 1em;
  }
}
@media (min-width: 768px) {
  .jobs-list {
    display: flex;
  }
  .jobs-list-item {
    align-self: center;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) and (max-height: 859.98px) {
  .jobs-list-item {
    font-size: 7vh;
  }
  .jobs-list-item:nth-child(odd) {
    align-self: start;
  }
  .jobs-list-item:nth-child(even) {
    align-self: end;
    margin-left: -1em;
    margin-right: 1em;
  }
}
@media (min-width: 768px) and (min-height: 860px) {
  .jobs-list-item:nth-child(5n+1) {
    align-self: start;
    margin-top: 3em;
  }
  .jobs-list-item:nth-child(5n+2) {
    margin-left: -1.5em;
  }
  .jobs-list-item:nth-child(5n+3) {
    align-self: end;
    margin-left: -2.4em;
  }
  .jobs-list-item:nth-child(5n+4) {
    align-self: start;
    margin-left: -5em;
  }
  .jobs-list-item:nth-child(5n+5) {
    margin-left: -0.8em;
    margin-top: 6em;
  }
  .jobs-list-item:nth-child(5n+5) + .jobs-list-item {
    margin-left: -1.2em;
  }
}

.tab-panel {
  font-size: 18px;
}
@media (max-width: 991.98px) {
  .tab-panel__content {
    display: none;
  }
}
@media (min-width: 992px) {
  .tab-panel {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .tab-panel .accordion {
    display: none !important;
  }
  .tab-panel__nav, .tab-panel__content {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
    flex: 0 0 auto;
    width: 50%;
  }
  .tab-panel__content {
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .tab-panel__nav {
    flex: 0 0 auto;
    width: 41.66666667%;
    margin-left: auto;
    margin-right: auto;
  }
}

.youtube-playlist {
  display: grid;
  gap: 3rem 1.5rem;
}
@media (min-width: 576px) {
  .youtube-playlist {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .youtube-playlist {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .youtube-playlist {
    grid-template-columns: repeat(4, 1fr);
  }
}

.box-youtube {
  --icon-color: #fff;
  position: relative;
}
.box-youtube__video {
  margin-bottom: 1rem;
  position: relative;
}
.box-youtube__video::before {
  transition: background-color 0.3s;
  content: "";
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  width: 2.1em;
  height: 1.7em;
  background-color: var(--icon-color);
  clip-path: polygon(0 0, 0 100%, 35% 100%, 35% 25%, 75% 50%, 25% 85%, 35% 100%, 100% 100%, 100% 0);
  border-radius: 0.2em;
}
@media (prefers-reduced-motion: reduce) {
  .box-youtube__video::before {
    transition: none;
  }
}
.box-youtube__video figure {
  aspect-ratio: 306/406;
  margin-bottom: 0;
}
.box-youtube__video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.box-youtube__title {
  transition: color 0.3s;
  font-size: 1rem;
  letter-spacing: 0.01em;
  font-weight: 800;
  margin-bottom: 0;
  text-align: center;
  text-transform: none;
}
@media (prefers-reduced-motion: reduce) {
  .box-youtube__title {
    transition: none;
  }
}
.box-youtube:hover {
  --icon-color: #FF0000;
}
@media (min-width: 768px) {
  .box-youtube .box-youtube__title > span {
    text-decoration: none;
    background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.1em/0 1px no-repeat;
    transition: background-size 0.9s cubic-bezier(0.19, 1, 0.22, 1), color 0.3s;
  }
  .box-youtube .box-youtube__title > span:hover, .box-youtube .box-youtube__title > span.active {
    text-decoration: none;
    background-position: 0 1.1em;
    background-size: 100% 1px;
  }
  .box-youtube .box-youtube__title > span:before {
    content: "";
    position: absolute;
    inset: 0;
  }
}

/* Page menu */
body.menu-active {
  --header-clip-top: 0;
  --header-clip-bottom: 1;
  overflow: hidden;
  transition: all 0.1s;
}

/* Menu content */
.header-content {
  padding-top: var(--header-padding-y);
  padding-bottom: calc(var(--container-padding-x) / 2);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%);
  pointer-events: none;
  background-color: rgba(22, 60, 96, 0.99);
  opacity: 0;
  visibility: hidden;
  color: #fff;
}
html.js .header-content {
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  html.js .header-content {
    transition: none;
  }
}
body.menu-active .header-content {
  pointer-events: auto;
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.header-content > * {
  padding-right: calc(var(--container-padding-x) / 2);
  padding-left: calc(var(--container-padding-x) / 2);
  width: 100%;
}

.header-bottom {
  font-size: 11px;
  margin-top: auto;
  padding-top: 2rem;
  text-align: center;
}
.header-bottom a {
  color: inherit;
}
.header-bottom .social-links__list {
  justify-content: center;
  margin-bottom: 1rem;
}
.header-bottom .social-links__list a {
  color: inherit;
  text-decoration: none;
}
.header-bottom .social-links__list a:focus, .header-bottom .social-links__list a:visited {
  color: inherit;
}
.header-bottom .social-links__list a:hover {
  color: #70e788;
}
.header-bottom .social-links__list a:hover, .header-bottom .social-links__list a:focus, .header-bottom .social-links__list a:active {
  text-decoration: none;
}
@media (min-width: 768px) {
  .header-bottom__footer {
    display: flex;
    column-gap: 1.75rem;
    justify-content: center;
  }
}

/* Menu Trigger */
@keyframes topBar_open {
  0% {
    transform: translateY(0em) rotate(0deg);
    width: 4.2857142857em;
  }
  10% {
    transform: translateY(-0.5714285714em) rotate(0deg);
    width: 4.2857142857em;
  }
  50% {
    transform: translateY(1.7857142857em) rotate(45deg);
    width: 6em;
  }
  75% {
    transform: translateY(0.8571428571em) rotate(45deg);
    width: 6em;
  }
  100% {
    transform: translateY(1.2142857143em) rotate(45deg);
    width: 6em;
  }
}
@keyframes bottomBar_open {
  0% {
    transform: translateY(0em) rotate(0deg);
    width: 4.2857142857em;
  }
  10% {
    transform: translateY(-0.5714285714em) rotate(0deg);
    width: 4.2857142857em;
  }
  60% {
    transform: translateY(0em) rotate(-45deg);
    width: 6em;
  }
  75% {
    transform: translateY(-0.7142857143em) rotate(-45deg);
    width: 6em;
  }
  100% {
    transform: translateY(-0.5714285714em) rotate(-45deg);
    width: 6em;
  }
}
@keyframes menuLabel_open {
  0% {
    transform: translateY(0em);
    opacity: 1;
  }
  25% {
    transform: translateY(-1.2857142857em);
  }
  45% {
    transform: translateY(3.1428571429em);
    opacity: 1;
  }
  48% {
    transform: translateY(3.5714285714em);
    opacity: 0;
  }
  100% {
    transform: translateY(2.1428571429em);
    opacity: 0;
  }
}
@keyframes topBar_close {
  0% {
    transform: translateY(1.2142857143em) rotate(45deg);
    width: 6em;
  }
  35% {
    transform: translateY(-0.5714285714em) rotate(-4deg);
    width: 4.2857142857em;
  }
  53% {
    transform: translateY(0.7142857143em) rotate(3deg);
    width: 4.2857142857em;
  }
  70% {
    transform: translateY(-0.4285714286em) rotate(0deg);
    width: 4.2857142857em;
  }
  100% {
    transform: translateY(-0.1428571429em) rotate(0deg);
    width: 4.2857142857em;
  }
}
@keyframes bottomBar_close {
  0% {
    transform: translateY(-0.5714285714em) rotate(-45deg);
    width: 6em;
  }
  35% {
    transform: translateY(-1.2857142857em) rotate(6deg);
    width: 4.2857142857em;
  }
  53% {
    transform: translateY(0em) rotate(-3deg);
    width: 4.2857142857em;
  }
  68% {
    transform: translateY(-0.5em) rotate(0deg);
    width: 4.2857142857em;
  }
  100% {
    transform: translateY(0em) rotate(0deg);
    width: 4.2857142857em;
  }
}
@keyframes menuLabel_close {
  0% {
    transform: translateY(2.1428571429em);
    opacity: 0;
  }
  5% {
    transform: translateY(1.7857142857em);
    opacity: 1;
  }
  25% {
    transform: translateY(-2.1428571429em);
    opacity: 1;
  }
  37% {
    transform: translateY(-1.5714285714em);
    opacity: 1;
  }
  45% {
    transform: translateY(-1.5714285714em);
    opacity: 1;
  }
  58% {
    transform: translateY(0.5714285714em) rotate(-10deg);
    opacity: 1;
  }
  83% {
    transform: translateY(-0.4285714286em) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(0em);
    opacity: 1;
  }
}
.menu-btn {
  --header-color: #163c60;
  font-size: clamp(7px, 1.5vw, 14px);
  display: block;
  position: relative;
  text-align: center;
  width: 4.2857142857em;
  height: 4.6428571429em;
  z-index: 9;
}
.menu-btn:before, .menu-btn:after {
  content: "";
  width: 100%;
  height: 0.57em;
  background-color: var(--header-color);
  display: block;
  position: absolute;
  border-radius: 1.4285714286em;
}
body.menu-active .menu-btn:before, body.menu-active .menu-btn:after {
  --header-color: #fff;
}
.menu-btn:before {
  transform-origin: left center;
}
.menu-btn:after {
  right: 0;
  top: 1.785em;
  transform-origin: right center;
}
.menu-btn__text {
  color: var(--color);
  font-family: "Avenir Next", sans-serif;
  display: block;
  position: absolute;
  bottom: 0;
  font-size: 1em;
  letter-spacing: 0.3em;
  font-weight: 700;
}
.menu-btn:hover {
  cursor: pointer;
}
body.menu-active .menu-btn:before {
  animation: topBar_open 1s ease-in-out;
  animation-fill-mode: forwards;
}
body.menu-active .menu-btn:after {
  animation: bottomBar_open 1s ease-in-out;
  animation-fill-mode: forwards;
}
body.menu-active .menu-btn .menu-btn__text {
  animation: menuLabel_open 1s ease-in;
  animation-fill-mode: forwards;
}
body.menu-close .menu-btn:before {
  animation: topBar_close 1s ease-in-out;
  animation-fill-mode: forwards;
}
body.menu-close .menu-btn:after {
  animation: bottomBar_close 1s ease-in-out;
  animation-fill-mode: forwards;
}
body.menu-close .menu-btn .menu-btn__text {
  animation: menuLabel_close 1s ease-in;
  animation-fill-mode: forwards;
}

/* Pages */
/* Homepage */