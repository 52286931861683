/* Footer */
$footer-breakpoint: md;
@include sticky-footer;

.layout-footer {
    background-color: $body-bg;
    position: relative;
    z-index: 1;
}

.footer-container {
    @include padding-top(40px);
    @include padding-bottom(40px);
    border-top: 1px solid $primary;

    @include media-breakpoint-up($footer-breakpoint) {
        @include make-row-col();
        justify-content: space-between;
    }
}

.footer-intro {
    @include media-breakpoint-down($footer-breakpoint){
        @include make-row-col();
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 1rem;
        margin-bottom: 2.5rem;
    }
}

.footer-visual {
    font-size: rem(11px);
    display: flex;
}

.footer-logo {
    font-size: clamp(50px, 6vw,72px);
    line-height: 1;

    a {
        display: flex;
    }

    img {
        max-width: 1em;
        margin-right: 0.45em;
    }
}

.footer-text {
    max-width: 14.75em;
    line-height: 1.6;
    font-weight: $font-weight-semibold;
}

.footer-content {
    font-size: rem(11px);
    letter-spacing: $letter-spacing;

    .social-links {
        @include media-breakpoint-up($footer-breakpoint) {
            margin-left: auto;
        }
    }
}

.footer-socials {
    font-size: 1rem;
    @include media-breakpoint-up($footer-breakpoint) {
        margin-bottom: 0.75rem;
    }
}

//  copyright + navigation
.footer-action {
    font-size: 11px;

    @include media-breakpoint-up($footer-breakpoint) {
        @include make-row-col(1.75rem);
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }
}

.footer-navigation {
    nav {
        margin-bottom: 0;
    }

    strong {
        font-weight: inherit;
        text-shadow: 0 0 .075em currentColor, 0 0 .075em currentColor;
    }

    ul {
        @include list-unstyled;
        @include list-unstyled;
        @include make-row-col(2rem);
        flex-wrap: wrap;
        margin-bottom: 0.5rem;

        li {
            position: relative;

            &:not(:last-child)::after {
                position: absolute;
                top: 0.4em;
                bottom: 0.4em;
                right: 0;
                width: 1px;
                background-color: currentColor;
                content: '';
            }

            a {
                @include link-underline-visible-hover()
            }
        }
    }

    @include media-breakpoint-down($footer-breakpoint) {
        ul {
            justify-content: center;
        }
    }
}

.footer-copyright {
    .coming-soon__footer & {
        margin-bottom: 0.5rem;
    }

    @include media-breakpoint-down($footer-breakpoint) {
        text-align: center;
    }
}
