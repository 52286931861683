.btn {
    text-transform: uppercase;
    letter-spacing: 0.02em;
}

.link-more-btn {
    text-align: center;
    @include padding-top(30px);

    a {
        @extend .btn;
        @extend .btn-outline-primary;
    }
}
