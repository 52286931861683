/* Gallery */
.ce_gallery {
    // Clear the default Contao styling
    .ce_gallery > ul li.col_first {
        clear: none;
    }

    ul {
        @include make-row();
        @include add-gutter-y;
        @include list-unstyled;
        margin-bottom: 0;
    }

    li {
        @include make-col-ready();
        @include make-col(6, 12);

        figure {
            margin-bottom: 0;
        }
    }

    @for $i from 1 through 12 {
        @include media-breakpoint-up(md) {
            ul.cols_#{$i} {
                li {
                    @include make-col(calc(12 / $i), 12);
                }
            }
        }
    }
}
