.banner-wrapper {
    margin-bottom: 0;
}

.banner {
    @include padding-top(60px);
    @include padding-bottom(60px);
    height: var(--banner-height);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $secondary;

    &__visual {
        text-align: center;
        margin-left: calc(var(--container-padding-x) * -0.5);
        margin-right: calc(var(--container-padding-x) * -0.5);
        height: 100%;

        figure {
            margin-bottom: 0;
        }

        figure,
        img {
            height: 100%;
        }
    }

    &__scroll {
        position: absolute;
        bottom: 1rem;
    }

    @include media-breakpoint-up(lg) {
        &__visual {
            &-inside {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}

