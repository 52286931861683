$jobs-image-link-breakpoint: md;

.jobs-list-wrapper {
    --content-element-margin: 0;
    background-color: $bg-dark;
    position: relative;

    &__content {
        --element-size: clamp(38px, 8vw, 70px);
        position: relative;
        width: 100%;
        padding-top: var(--header-padding-y);
        padding-bottom: var(--header-padding-y);
    }

    &__bg {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;

        img {
            max-width: 110%;
            max-height: 100%;
            top: 50vh;
            left: 50vw;
            transform: translate(-50%, -50%);
        }
    }

    @include media-breakpoint-up($jobs-image-link-breakpoint) {
        &__content {
            --element-size: min(clamp(36px, 8vw, 70px), 4.5vh);
            height: 100vh;
        }

        &__bg {
            img {
                max-width: 70%;
                max-height: 70%;
            }
        }
    }
}

.jobs-list {
    z-index: 1;
    padding-left: calc(var(--container-padding-x) * 0.5);
    padding-right: calc(var(--container-padding-x) * 0.5);
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    &-item {
        $this: &;
        position: relative;
        aspect-ratio: 1;
        background: $primary-light;
        width: 6.25em;
        font-size: var(--element-size);

        &__visual {
            figure {
                margin-bottom: 0;

                img {
                    width: 100%;
                }
            }
        }

        &__content {
            @extend %scrollbar-style;
            @include transition(opacity $transition-time);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            background-color: rgba(#459eef, 0.9);
            padding: .5em 0.3em;
            opacity: 0;

            &-title {
                @extend %text-hyphens;
                font-size: 0.8em;
                line-height: calc(54 / 48);
                margin-bottom: 0.5em;
            }

            &-link {
                display: flex;
                justify-content: center;

                a {
                    font-size: 0.33em;
                }
            }
        }

        &:hover {
            z-index: 2;

            #{$this}__content {
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-down($jobs-image-link-breakpoint) {
        &-item {
            margin-bottom: 1em;
        }
    }

    @include media-breakpoint-up($jobs-image-link-breakpoint) {
        display: flex;

        &-item {
            align-self: center;
            flex-shrink: 0;

            @media (max-height: 859.98px) {
                font-size: 7vh;

                &:nth-child(odd) {
                    align-self: start;
                }

                &:nth-child(even) {
                    align-self: end;
                    margin-left: -1em;
                    margin-right: 1em;
                }
            }

            @media (min-height: 860px) {
                &:nth-child(5n+1) {
                    align-self: start;
                    margin-top: 3em;
                }

                &:nth-child(5n+2) {
                    margin-left: -1.5em;
                }

                &:nth-child(5n+3) {
                    align-self: end;
                    margin-left: -2.4em;
                }

                &:nth-child(5n+4) {
                    align-self: start;
                    margin-left: -5em;
                }

                &:nth-child(5n+5) {
                    margin-left: -0.8em;
                    margin-top: 6em;

                    + .jobs-list-item {
                        margin-left: -1.2em;
                    }
                }
            }
        }
    }
}
