.team-text-wrapper {
    --headings-color: #{$white};
    --headings-font-weight: $font-weight-normal;
    @include padding-top(30px);
    @include padding-bottom(30px);
    background-color: $bg-dark;
    color: $white;
    margin-bottom: 0;
}

.team-text {
    font-size: 16px;
    letter-spacing: $letter-spacing;

    &__intro {
        position: relative;
        left: 50%;
        width: calc(100vw - var(--scrollbar-width));
        transform: translateX(-50%);
        margin-bottom: 2.1em;
        font-size: 3.75em;

        &::after {
            --icon-size: 3.6em;
            position: absolute;
            width: var(--icon-size);
            height: var(--icon-size);
            left: -1em;
            top: calc(100% - .45em);
            content: '';
            background-image: url('../../../images/check-circle.svg');
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__headline {
        margin-bottom: 0;
        font-size: 1em;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &__text {
        @extend %text-hyphens;
        margin-left: auto;
        max-width: 15.5em;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(sm) {
        &__intro {
            &::after {
                left: calc(50% - var(--icon-size));
            }
        }
    }

    @include media-breakpoint-up(md) {
        @include font-size(18px);

        &__intro {
            font-size: 7em;
            margin-bottom: 0.4em;

            &::after {
                --icon-size: 2em;
                left: calc(50% - var(--icon-size));
                top: calc(100% - 0.75em);
            }
        }

        &__text {
            max-width: 32.8em;
        }
    }

    @include media-breakpoint-up(xl) {
        &__intro {
            font-size: 10em;
        }
    }
}
