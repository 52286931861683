/* Miscellaneous */
:root {
    --scrollbar-width: #{'0px'};
    --banner-height: max(100dvh, 20vw);
    --header-height: clamp(50px, 7vw, 102px);
    --header-padding-y: clamp(30px, 5vw, 60px);
    @include rwd-property('--container-padding-x', container-padding-x);
    @include rwd-property('--container-margin-top', container-margin-top);
    @include rwd-property('--container-margin-bottom', container-margin-bottom);
    @include rwd-property('--article-margin', article-margin);
    @include rwd-property('--content-element-margin', content-element-margin);
    --header-offset-y: calc(var(--header-height) + (var(--header-padding-y) * 2));
}

.header-container,
.layout-footer__inside {
    @extend .container-xxl;
}

body.container-reset {
    background-color: $secondary;
}

html,
body {
    overflow-x: hidden;
}

.layout-container {
    position: relative;
    z-index: 1;
}

.ce_text {
    a {
        @include link-underline-visible-hover();
    }
}
