.mouse {
    @include rfs(42px, --size);
    --nudgeMouse: calc(var(--size) * 0.215);
    width: var(--size);
    height: calc(var(--size) * 2);
    animation: nudgeMouse 5.5s ease-out infinite;
    position: relative;
    font-size: var(--size);

    &:after {
        font-size: calc(var(--size) * 2.75);
        content: '';
        background-color: $primary;
        width: calc(var(--size) * 0.165);
        height: calc(var(--size) * 0.165);
        border-radius: 100%;
        animation: trackBallSlide 5.5s linear infinite;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
    }

    &__frame {
        width: 100%;
        position: absolute;
        z-index: 1;

        path {
            fill: none;
            stroke: #ffffff;
            stroke-width: 3;
            opacity: 0.5;
        }
    }

    &__left,
    &__right {
        width: 50%;
        position: absolute;
        z-index: 2;

        path {
            fill: none;
            stroke: $primary;
            stroke-width: 3;
            fill-opacity: 1;
        }
    }

    &__left {
        animation: DrawLine 5.5s ease-out infinite;
    }

    &__right {
        right: 0;
        animation: DrawLineBack 5.5s ease-out infinite;
    }
}


/*Keyframes*/
@keyframes DrawLine {
    0% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    22% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    38% {
        stroke-dashOffset: 125;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    38.1% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 0;
    }
    48% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    64% {
        stroke-dashOffset: 125;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    64.1% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 0;
    }
    74% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    90% {
        stroke-opacity: 1;
        stroke-dashOffset: 125;
        stroke-dasharray: 120;
    }
    90.1% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 0;
    }
}

@keyframes DrawLineBack {
    0% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    22% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    38% {
        stroke-dashOffset: -114;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    38.1% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 0;
    }
    48% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    64% {
        stroke-dashOffset: -114;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    64.1% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 0;
    }
    74% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 1;
    }
    90% {
        stroke-opacity: 1;
        stroke-dashOffset: -114;
        stroke-dasharray: 120;
    }
    90.1% {
        stroke-dashOffset: 0;
        stroke-dasharray: 120;
        stroke-opacity: 0;
    }
}

@keyframes nudgeMouse {
    0% {
        transform: translateY(0);
    }
    22% {
        transform: translateY(0px)
    }
    38% {
        transform: translateY(var(--nudgeMouse));
    }
    48% {
        transform: translateY(0px);
    }
    64% {
        transform: translateY(var(--nudgeMouse));
    }
    74% {
        transform: translateY(var(--nudgeMouse));
    }
    90% {
        transform: translateY(var(--nudgeMouse));
    }
}

/*Mouse wheel*/
@keyframes trackBallSlide {
    0% {
        transform: translateY(0.2em) scale(1);
        opacity: 1;
    }
    22% {
        transform: translateY(0.2em) scale(1);
        opacity: 1;
    }
    26% {
        transform: translateY(0.3em) scale(0.9);
        opacity: 1;
    }
    34% {
        transform: translateY(0.55em) scale(0.1);
        opacity: 0;
    }
    41% {
        transform: translateY(0.3em) scale(0);
        opacity: 0.3;
    }
    48% {
        transform: translateY(0.2em) scale(1);
        opacity: 1;
    }
    52% {
        transform: translateY(0.3em) scale(0.9);
        opacity: 1;
    }
    60% {
        transform: translateY(0.55em) scale(0.1);
        opacity: 0;
    }
    67% {
        transform: translateY(0.3em) scale(0);
        opacity: 0.3;
    }
    74% {
        transform: translateY(0.2em) scale(1);
        opacity: 1;
    }
    78% {
        transform: translateY(0.3em) scale(0.9);
        opacity: 1;
    }
    86% {
        transform: translateY(0.55em) scale(0.1);
        opacity: 0;
    }
    93% {
        transform: translateY(0.3em) scale(0);
        opacity: 0.3;
    }
    100% {
        transform: translateY(0.2em) scale(1);
        opacity: 1;
    }
}
