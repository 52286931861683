.job-reader {
    position: relative;
    --section-margin: calc(var(--article-margin) * 0.7);
    margin-bottom: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #459eef;
        z-index: -2;
    }
}

.job-intro {
    padding-bottom: calc(var(--article-margin) * 0.5);
    padding-top: var(--header-offset-y);
    position: relative;
    z-index: 1;

    &__title {
        --headings-color: #{$white};
        --headings-font-weight: $font-weight-normal;
        letter-spacing: $letter-spacing;
        @include font-size(150px);
        @include margin-bottom(60px);
        margin-bottom: 1em;
        line-height: 1;
    }

    &__headline {
        --headings-color: #{$secondary};
        line-height: calc(67 / 56);
        position: relative;
        z-index: 1;
        margin-bottom: -.6em;
        max-width: 16em;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &__body {
        &-visual {
            margin-bottom: var(--content-element-margin);
        }

        &-content {
            font-size: 24px;

            &-text {
                @extend %text-hyphens;
                font-weight: $font-weight-semibold;
                letter-spacing: $letter-spacing;

                > *:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__headline {
            font-size: 40px;
        }
    }

    @include media-breakpoint-up(md) {
        padding-bottom: calc(var(--section-margin) * .5);

        &__title {
            margin-bottom: 0.4em;
            line-height: 0.9;
        }

        &__body {
            @include make-row(0);

            &-visual,
            &-content {
                @include make-col-ready;
                @include make-col(6);
            }

            &-visual {
                margin-bottom: 200px;

                &-inside {
                    width: calc(50vw - var(--scrollbar-width) / 2);
                    position: relative;
                    float: right;

                    img {
                        width: 100%;
                        max-height: 723px;
                        object-fit: cover;
                    }
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                padding-left: 2em;

                &-text {
                    max-width: 17em;
                    margin-left: auto;
                    margin-top: auto;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__body-content {
            font-size: 30px;
        }
    }
}

.job-details {
    @include add-section-bg-100vw($secondary);
    padding-top: calc(var(--article-margin) * .5);
    padding-bottom: var(--article-margin);
    --bg-triangle-size: min(100vh, 70vw);
    --bg-offset-top: min(65vh, 35vw);

    &::after {
        top: var(--bg-offset-top, 0);
    }

    &::before {
        content: '';
        position: absolute;
        bottom: calc(100% + (var(--bg-offset-top, 0) * -1));
        left: 50%;
        z-index: -1;
        width: calc(100vw - var(--scrollbar-width)) !important;
        transform: translateX(-50%);
        @include triangle(bottomright, var(--bg-triangle-size), 100vw, $secondary);
    }

    &__headline {
        line-height: 1;
        margin-bottom: 1.35em;
    }

    .ce_youtube {
        @extend .youtube-center;
        margin-bottom: var(--article-margin);
    }

    @include media-breakpoint-up(md) {
        padding-top: calc(var(--section-margin) * .5);

        &__headline {
            margin-bottom: 1.85em;
        }
    }
}

