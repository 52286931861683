/* Mixins */

/* Vertical align */
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin vertical-align-parent() {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

/* Sticky footer */
@mixin sticky-footer() {
    html {
        position: relative;
        min-height: 100%;
    }

    body {
        display: flex;
        flex-direction: column;
    }

    .layout-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        > *:not(.layout-container) {
            flex-shrink: 0;
        }
    }

    .layout-container {
        flex: 1 1 auto;
    }
}

/* Link variant */
@mixin link-variant($color: inherit, $hovercolor: null, $link-decoration: $link-decoration, $link-hover-decoration: $link-hover-decoration) {
    color: $color;

    &:focus,
    &:visited {
        color: $color;
    }

    @if ($hovercolor) {
        &:hover {
            color: $hovercolor;
        }
    } @else {
        @if ($color == inherit) {
            &:hover {
                opacity: 0.8;
            }
        } @else {
            &:hover {
                color: darken($color, 20%);
            }
        }
    }

    @if ($link-decoration) {
        text-decoration: $link-decoration;
    }

    @if ($link-hover-decoration) {
        &:hover,
        &:focus,
        &:active {
            text-decoration: $link-hover-decoration;
        }
    } @else {
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}

/* Add icon */
@mixin icon($icon: '', $position: before, $margin: null, $font-size: null, $color: null) {
    &::#{$position} {
        @if $icon {
            font-weight: normal;
            font-family: 'icomoon' !important;
            font-style: normal;
            font-variant: normal;
            line-height: 1;
            text-transform: none;
            content: $icon;
            speak: none;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        @if $margin {
            @if ($position == before) {
                margin-right: $margin;
            }

            @if ($position == after) {
                margin-left: $margin;
            }
        }

        @if $font-size {
            font-size: $font-size;
        }

        @if $color {
            color: $color;
        }
    }
}

/* Px to rem */
@function rem($size, $base: $font-size-desktop) {
    @return calc($size / $base) * 1rem;
}

@function pxToEm($size, $base: 14px) {
    @return calc($size / $base) * 1em;
}

/* Dropdown navigation */
@mixin dropdown-menu($left: 0) {
    ul {
        @include list-unstyled;
        margin-bottom: 0;
    }

    ul.level_1 {
        display: flex;

        ul {
            @include transition(transform $transition-time, visibility $transition-time, opacity $transition-time);
            position: absolute;
            top: 100%;
            left: $left;
            z-index: 2;
            width: $dropdown-menu-width;
            color: $dropdown-menu-color;
            font-size: $dropdown-menu-font-size;
            background-color: $dropdown-menu-bg;
            box-shadow: $dropdown-menu-shadow;
            transform: perspective(600px) rotateX(-90deg);
            transform-origin: top;
            visibility: hidden;
            opacity: 0;

            a, strong {
                display: block;
                padding: $dropdown-menu-item-padding-y $dropdown-menu-item-padding-x;

                &:not(.last) {
                    border-bottom: $dropdown-menu-item-border-width solid $dropdown-menu-item-border-color;
                }

                &.active,
                &.trail {
                    color: $dropdown-menu-item-active-color;
                    background-color: $dropdown-menu-item-active-bg;
                }
            }

            a {
                @include link-variant(inherit, $dropdown-menu-item-hover-color);

                &:hover {
                    background-color: $dropdown-menu-item-hover-bg;
                }
            }

            li.submenu:hover {
                > a,
                > strong {
                    background-color: $dropdown-menu-item-hover-bg;
                }
            }

            ul {
                top: 0;
                left: 100%;
            }
        }
    }

    li.submenu {
        position: relative;

        &:hover {
            > ul {
                transform: perspective(600px) rotateX(0);
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

/* Add responsive properties */
@mixin rwd-property($property, $map, $reverse: false, $map-name: $custom-responsive-values, $breakpoints: $grid-breakpoints) {
    @if ($reverse) {
        $reverse: -1;
    } @else {
        $reverse: 1;
    }

    @if type-of($map) == 'string' {
        @if (map-has-key($map-name, $map)) {
            $map: map_get($map-name, $map);
        } @else {
            @warn "The '#{$map}' property does not exist in the $custom-spacers variable";
        }
    }

    @each $breakpoint, $value in $map {
        @if type-of($value) == 'number' {
            $value: $reverse * $value;
        }

        @if map-has-key($breakpoints, $breakpoint) {
            @include media-breakpoint-up($breakpoint) {
                #{$property}: $value;
            }
        } @else {
            @media (min-width: $breakpoint) {
                #{$property}: $value;
            }
        }
    }
}

/* Make row and columns */
@mixin make-row-col($gutter: $grid-gutter-width) {
    --#{$variable-prefix}gutter-x: #{$gutter};
    display: flex;
    margin-right: calc(var(--#{$variable-prefix}gutter-x) / -2);
    margin-left: calc(var(--#{$variable-prefix}gutter-x) / -2);

    > * {
        padding-right: calc(var(--#{$variable-prefix}gutter-x) / 2);
        padding-left: calc(var(--#{$variable-prefix}gutter-x) / 2);
    }
}

/* Set gutter-y variable */
@mixin add-gutter-y($gutter: var(--#{$variable-prefix}gutter-x)) {
    --#{$variable-prefix}gutter-y: #{$gutter};
}

// Link underline
@mixin link-underline($hoverColor: currentColor, $color: currentColor, $spacing: 1.2em, $size: 1px) {
    text-decoration: none;
    background: linear-gradient(to right, #{$hoverColor} 0%, #{$hoverColor} 100%) 100% #{$spacing} / 0 #{$size} no-repeat;
    transition: background-size ($transition-time * 3) cubic-bezier(0.19, 1, 0.22, 1), color $transition-time;

    &:hover,
    &.active {
        text-decoration: none;
        background-position: 0 $spacing;
        background-size: 100% #{$size};
    }
}

@mixin link-underline-visible($hoverColor: currentColor, $color: currentColor, $spacing: 1.2em, $size: 1px) {
    text-decoration: none;
    background: linear-gradient(to right, #{$hoverColor} 0%, #{$hoverColor} 100%) 100% #{$spacing} / 0 #{$size} no-repeat;
    background-position: 0 $spacing;
    background-size: 100% #{$size};
    transition: background-size ($transition-time * 2) cubic-bezier(0.19, 1, 0.22, 1), color $transition-time;
}

@mixin link-underline-visible-hover($hoverColor: currentColor, $color: currentColor, $spacing: 1.2em, $size: 1px) {
    --underline-size: #{$size};
    @include link-underline-visible($hoverColor, $color, $spacing, $size);

    &:hover {
        animation: linkUnderlineVisibleAnimate $transition-time;
    }
}

@keyframes linkUnderlineVisibleAnimate {
    from {
        background-size: 0 var(--underline-size);
    }

    to {
        background-size: 100% var(--underline-size);
    }
}

/* Add section bg */
@mixin add-section-bg-100vw($color) {
    position: relative;

    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        z-index: -2;
        width: calc(100vw - var(--scrollbar-width));
        background-color: $color;
        transform: translateX(-50%);
        content: '';
    }
}

/*
* SCSS CSS triangle mixin, create any kind of triangles with ease
*/
@mixin triangle($direction, $sizeH, $sizeV, $color) {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;

    @if $direction == top {
        border-width: 0 $sizeV $sizeH $sizeV;
        border-color: transparent transparent $color transparent;
    }

    @if $direction == bottom {
        border-width: $sizeV $sizeH 0 $sizeV;
        border-color: $color transparent transparent transparent;
    }

    @if $direction == left {
        border-width: $sizeV $sizeH $sizeV 0;
        border-color: transparent $color transparent transparent;
    }

    @if $direction == right {
        border-width: $sizeV 0 $sizeV $sizeH;
        border-color: transparent transparent transparent $color;
    }

    @if $direction == topright {
        border-width: 0 $sizeH $sizeV 0;
        border-color: transparent $color transparent transparent;
    }

    @if $direction == bottomright {
        border-width: 0 0 $sizeH $sizeV;
        border-color: transparent transparent $color transparent;
    }

    @if $direction == bottomleft {
        border-width: $sizeH 0 0 $sizeV;
        border-color: transparent transparent transparent $color;
    }

    @if $direction == topleft {
        border-width: $sizeH $sizeV 0 0;
        border-color: $color transparent transparent transparent;
    }
}
