// Image hover
%image-hover {
    @include icon($image-hover-icon, after);
    position: relative;
    display: inline-block;
    max-width: 100%;
    text-decoration: none;
    vertical-align: top;

    img {
        display: block;
    }

    &::before {
        @include transition(opacity $transition-time);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: rgba($black, 0.4);
        opacity: 0;
        content: '';
        mix-blend-mode: multiply;
    }

    &::after {
        @include transition(transform $transition-time, opacity $transition-time);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        padding: $image-hover-icon-padding;
        color: $image-hover-icon-color;
        font-size: $image-hover-icon-font-size;
        text-align: center;
        background-color: $image-hover-icon-background-color;
        border-radius: $image-hover-icon-border-radius;
        transform: translate(-50%, 50%);
        opacity: 0;
    }

    &:hover {
        &::before {
            opacity: 1;
        }

        &::after {
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }
}

.ce_gallery,
.ce_image,
.ce_text {
    .image_container {
        @include clearfix;

        a[data-lightbox] {
            @extend %image-hover;
        }
    }
}

.caption {
    font-size: 0.9em;
    color: $text-muted;
    font-style: italic;
}
