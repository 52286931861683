.youtube-playlist {
    display: grid;
    gap: $grid-gutter-width * 2 $grid-gutter-width;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.box-youtube {
    $this: &;
    --icon-color: #fff;
    position: relative;

    &__video {
        margin-bottom: 1rem;
        position: relative;

        &::before {
            @include transition(background-color $transition-time);
            content: '';
            position: absolute;
            left: 1rem;
            bottom: 1rem;
            width: 2.1em;
            height: 1.7em;
            background-color: var(--icon-color);
            clip-path: polygon(0 0,0 100%,35% 100%,35% 25%,75% 50%,25% 85%,35% 100%,100% 100%,100% 0);
            border-radius: 0.2em;
        }

        figure {
            aspect-ratio: 306 / 406;
            margin-bottom: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
        }
    }

    &__title {
        @include transition(color $transition-time);
        @include font-size(18px);
        letter-spacing: $letter-spacing;
        font-weight: $font-weight-extrabold;
        margin-bottom: 0;
        text-align: center;
        text-transform: none;
    }

    &:hover {
        --icon-color: #FF0000;
    }

    @include media-breakpoint-up(md) {
        #{$this}__title {
            > span {
                @include link-underline($spacing: 1.1em);

                &:before {
                    content: '';
                    position: absolute;
                    inset: 0;
                }
            }
        };
    }
}
