[data-animation-gradient-text] {
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    .char {
        background-color: rgba(255, 255, 255, 0.1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

[data-animation-horizontal] {
    display: flex;
    position: relative;
    z-index: 1;
    width: 100%;
}

[data-animation="text"] {
    transform: translateY(10%);
    opacity: 0;
}

[data-animation="image-slide-down"] {
    img {
        clip-path: inset(0 0 100% 0),
    }
}

.intro-text-wrapper {
    top: 0;
    width: 100%;
}
