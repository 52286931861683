.marquee {
    --offset: #{'0px'};
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    --animation-display: 60s;
    position: relative;
    overflow: hidden;
    padding-top: 0.1em;
    margin-top: -0.1em;
    white-space: nowrap;

    .job-intro & {
        --offset: 25%;
        --animation-display: 30s;
    }
}

.marquee__inner {
    position: relative;
    display: flex;
    width: fit-content;
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee var(--animation-display) linear infinite;

    &--inverted {
        animation: marquee-inverted var(--animation-display) linear infinite;
    }
}

@keyframes marquee {
    0% {
        transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        transform: translate3d(var(--move-final), 0, 0);
    }
}

@keyframes marquee-inverted {
    0% {
        transform: translate3d(var(--move-final), 0, 0);
    }

    100% {
        transform: translate3d(var(--move-initial), 0, 0);
    }
}
