html {
    .glightbox-clean .gslide-description {
        background: $secondary;
    }

    .glightbox-clean .gslide-title {
        @include font-size(18px);
        font-family: $font-family-base;
        color: $body-color;
        text-transform: none;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .goverlay {
        background: rgba(5,34,61,.95) !important;
    }

    @media (max-width: 768.98px) {
        .glightbox-clean .gdesc-inner {
            padding: 0;
        }

        .glightbox-mobile .glightbox-container .gslide-description {
            padding-bottom: 10px;
        }
    }
}


