/* IcoMoon fonts */
$icomoon-font-path: '../icons/fonts';

// Components
$transition-time: 0.3s;
$letter-spacing: 0.01em;

$aspect-ratios: (
    '1x1': 100%,
    '4x3': calc(3 / 4 * 100%),
    '43': calc(3 / 4 * 100%),
    '3x2': calc(2 / 3 * 100%),
    '32': calc(2 / 3 * 100%),
    '16x9': calc(9 / 16 * 100%),
    '169': calc(9 / 16 * 100%),
    '16x10': calc(10 / 16 * 100%),
    '1610': calc(10 / 16 * 100%),
    '21x9': calc(9 / 21 * 100%),
    '219': calc(9 / 21 * 100%),
);

// Color system
$primary: #163c60;
$primary-light: lighten(#163c60, 15%);
$secondary: #70e788;
$bg-dark: #193754;
$min-contrast-ratio: 3.5;

// Typography
$font-size-base: 1rem;
$font-size-desktop: 18px;
$font-size-tablet: 16px;
$font-size-mobile: 14px;
$line-height-base: calc(30 / 18);
$font-family-base: 'Montserrat Variable', Open Sans, sans-serif;
$font-weight-semibold: 600;
$font-weight-extrabold: 800;
$font-weight-black: 900;

// RFS
$rfs-rem-value: strip-units($font-size-desktop);

// Headings
$h1-font-size: 56px;
$h2-font-size: 56px;
$h3-font-size: 32px;
$h4-font-size: 22px;
$h5-font-size: 18px;
$h6-font-size: 16px;
$headings-line-height: calc(50 / 40);
$headings-font-weight: var(--headings-font-weight, $font-weight-black);
$headings-color: var(--headings-color, $primary);

// Body
$body-color: $primary;
$body-bg: #fff;

// Links
$link-decoration: none;

/* Vertical rhythm */
$vr: $line-height-base * 0.8rem;

// Container padding
$container-padding-x: var(--container-padding-x);

// Grid containers
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1355px
) !default;

// Responsive values
$custom-responsive-values: (
    container-padding-x: (
        xs: 40px,
        md: clamp(60px, 10vw, 120px),
    ),
    container-margin-top: (
        xs: $vr,
        md: $vr * 1.5,
        lg: $vr * 2,
    ),
    container-margin-bottom: (
        xs: $vr,
        md: $vr * 1.5,
        lg: $vr * 2,
    ),
    article-margin: (
        xs: 9.75rem,
        md: $vr * 3,
        lg: 10.5rem,
    ),
    content-element-margin: (
        xs: 3rem,
        md: $vr * 1.5,
        lg: $vr * 2,
    )
);

/* Mobile menu */
$mobile-menu-light-bg: $body-bg;
$mobile-menu-light-color: $body-color;
$mobile-menu-dark-bg: #333;
$mobile-menu-dark-color: #ddd;
$mobile-menu-font-size: $font-size-base;

$mobile-menu-trigger-bg: transparent;
$mobile-menu-trigger-color: $primary;
$mobile-menu-trigger-gap: 5px;
$mobile-menu-trigger-height: 4px;
$mobile-menu-trigger-padding: 0;
$mobile-menu-trigger-space: 7px;
$mobile-menu-trigger-transition: transform $transition-time ease-in-out;
$mobile-menu-trigger-width: 30px;

/* Desktop dropdown navigation */
$dropdown-menu-bg: $primary;
$dropdown-menu-color: #fff;
$dropdown-menu-font-size: $font-size-base;
$dropdown-menu-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
$dropdown-menu-width: rem(250px);

$dropdown-menu-item-border-color: rgba(255, 255, 255, 0.3);
$dropdown-menu-item-border-width: 1px;
$dropdown-menu-item-padding-x: 1rem;
$dropdown-menu-item-padding-y: 1rem;
$dropdown-menu-item-hover-bg: rgba(0, 0, 0, 0.1);
$dropdown-menu-item-hover-color: inherit;

$dropdown-menu-item-active-bg: rgba(0, 0, 0, 0.2);
$dropdown-menu-item-active-color: #fff;

// Element : Download
$download-link-color: inherit;
$download-link-hover-color: $primary-light;
$download-link-decoration: none;
$download-link-hover-decoration: $download-link-decoration;
$download-icon: $icon-download;

// Image Hover
$image-hover-icon-background-color: $primary;
$image-hover-icon-border-radius: 0;
$image-hover-icon-color: #fff;
$image-hover-icon-font-size: 1.2rem;
$image-hover-icon-padding: 0.8rem;
$image-hover-icon: $icon-search;

// Accordion
$accordion-element-gap: 5px;

// Buttons + Forms
$input-btn-font-family: inherit;

// Buttons
$btn-font-weight: $font-weight-extrabold;
$btn-padding-x: 1.6em;
$btn-border-width: 3px;
$btn-border-radius: 0;
$btn-font-size: rem(16px);

// Scrollbar
$scrollbar-size: 5px;
$scrollbar-border-color: $primary-light;
$scrollbar-border-width: 1px;
$scrollbar-bg: $primary-light;
$scrollbar-thumb-bg: #000;

// Accordion
$accordion-element-gap: 1.25rem;
$accordion-font-size: 18px;
$accordion-min-height: calc(3.25 * #{$line-height-base} * 1em);
$accordion-button-padding-y: 18px;
$accordion-button-padding-x: 28px;
$accordion-body-padding-y: 28px;
$accordion-body-padding-x: $accordion-button-padding-x;
$accordion-bg: transparent;
$accordion-button-active-bg: #fff;
$accordion-border-color: $primary;
$accordion-border-width: rem(3px);

// Nav tabs
$nav-link-font-weight: $font-weight-extrabold;
