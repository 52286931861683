%list-check {
    @include list-unstyled;
    letter-spacing: 0.001em;

    li {
        @include icon($icon-check-circle);
        margin-bottom: 0.75rem;
        position: relative;
        padding-left: 1.75em;

        &::before {
            position: absolute;
            top: 0.3em;
            left: 0;
            font-weight: $font-weight-semibold;
        }

        strong {
            font-weight: $font-weight-semibold;
        }
    }
}

.list-check,
.accordion,
.tab-content {
    ul {
        @extend %list-check;

        li:last-child {
            margin-bottom: 0;
        }
    }
}

.list-check {
    ul {
        padding-left: 2rem;
    }
}
