%heading {
    text-transform: uppercase;
    color: $headings-color;
}

.section-headline {
    margin-bottom: var(--content-element-margin);

    &--center {
        text-align: center;
    }

    &--center-limit-width {
        text-align: center;
        max-width: 49em;
        margin-inline: auto;
        @include margin-bottom(68px);
    }

    &--center,
    &--center-limit-width {
        h1 {
            font-size: clamp(50px, 5vw, $h2-font-size);

            @media (max-width: 410px) {
                font-size: 12vw;
            }
        }
    }
}

h2, .h2 {
    font-size: clamp(50px, 5vw, $h2-font-size);
}

h1, .h1 {
    line-height: 1;
}
