/* Header */
.header-container {
    padding-top: var(--header-padding-y);
    padding-bottom: var(--header-padding-y);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    pointer-events: none;

    &__inside,
    &__inside-copy {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    &__inside {
        position: relative;
        z-index: 1;

        > .menu-btn {
            pointer-events: auto;
        }
    }

    &__inside-copy {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 10;
        --header-white-clip-top: calc(var(--header-clip-top, 0.5) * 100%);
        --header-white-clip-bottom: calc(var(--header-clip-bottom, 0.5) * 100%);
        clip-path: polygon(0% var(--header-white-clip-top), 100% var(--header-white-clip-top), 100% var(--header-white-clip-bottom), 0% var(--header-white-clip-bottom));

        .header-logo {
            filter: brightness(0) invert(1);
        }

        .menu-btn {
            &::before,
            &::after,
            &__text {
                filter: brightness(0) invert(1);
            }
        }
    }
}

.header-logo {
    height: var(--header-height);
    z-index: 2;
    position: relative;
    pointer-events: auto;
    width: fit-content;

    img {
        display: block;
        height: 100%;
        width: auto;
    }
}

.header-navigation {
    @extend %scrollbar-style;
    margin-top: auto;

    nav {
        font-size: min(clamp(25px, 5vw, 55px), 5vh);
        font-weight: $font-weight-semibold;
        text-align: center;
        text-transform: uppercase;

        ul {
            @include list-unstyled;
            margin-bottom: 0;

            &.level_1 {
                > li {
                    margin-bottom: 0.35em;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.level_2 {
                text-transform: none;
                font-size: 0.65em;
                font-weight: $font-weight-base;
            }
        }

        a {
            @include link-variant($white, $secondary);
        }

        strong {
            font-weight: inherit;
            color: $secondary;
        }
    }
}
