/* Download */

.ce_download + .ce_download {
    > .download-element:first-child {
        margin-top: calc(var(--content-element-margin) * -1);
    }
}

.ce_downloads {
    ul {
        @include list-unstyled();
    }
}

.download-element {
    a {
        @include link-variant($download-link-color, $download-link-hover-color, $download-link-decoration, $download-link-hover-decoration);
        display: flex;
        width: fit-content;

        @if variable-exists(download-icon) {
            @include icon($download-icon);

            &::before {
                position: relative;
                margin-right: 0.8em;
                font-size: 0.9em;
                line-height: inherit;
                opacity: 0.8;
                top: 0.075em * $line-height-base;
            }
        }
    }

    .size {
        position: relative;
        top: 0.2em * $line-height-base;
        padding-left: 0.8em;
        font-size: 0.8em;
        white-space: nowrap;
        opacity: 0.8;
    }
}
