.job-list-widget {
    padding-top: calc(var(--article-margin) * 1.2);
    padding-bottom: var(--article-margin);
    min-height: 100vh;
    background-image: url("../../../images/bg-text-deco.svg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    &__container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__content {
        background-color: $gray-100;
        width: 100%;
        flex-grow: 1;
        display: flex;

        iframe {
            width: 100%;
            min-height: 500px;
        }
    }
}


