/* Helper class */

/* Reset style */
%reset {
    margin: 0;
    padding: 0;
    list-style: none;
    background: transparent;
    border: 0;
}

.reset {
    @extend %reset;
}

/* Links */
a {
    @include transition(color $transition-time);
}

/* Text */
%text-hyphens {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

// Custom scrollbar
%scrollbar-style {
    overflow: auto;

    &::-webkit-scrollbar {
        width: $scrollbar-size;

        height: $scrollbar-size;

        background-color: $scrollbar-bg;
    }

    &::-webkit-scrollbar-track {
        background-color: $scrollbar-bg;
        box-shadow: inset 0 0 0 $scrollbar-border-width $scrollbar-border-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-bg;
        border: solid $scrollbar-border-color;
        border-width: 0 $scrollbar-border-width;
    }
}
