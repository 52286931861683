/* Accordion */
.ce_accordion {
    @extend %text-hyphens;
    font-size: $accordion-font-size;
    letter-spacing: $letter-spacing;

    .toggler {
        display: flex;
        align-items: center;
        min-height: $accordion-min-height;
        width: 100%;
        padding: $accordion-button-padding-y $accordion-button-padding-x;
        border: $accordion-border-width solid $accordion-border-color;
        font-weight: $font-weight-extrabold;
        color: $accordion-button-color;
        @include transition($accordion-transition);
    }

    .accordion {
        height: 0;
        overflow: hidden;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;
        -webkit-transition-property: height, visibility;
        transition-property: height, visibility;

        > div {
            padding: $accordion-body-padding-y $accordion-body-padding-x;
            color: $accordion-color;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.active .toggler {
        background-color: $accordion-button-active-bg;
    }

    + .ce_accordion {
        margin-top: calc(var(--content-element-margin) * -1 + #{$accordion-element-gap});
    }

    @include media-breakpoint-down(md) {
        font-size: 24px;
    }
}
