.section--simple-content {
    @extend .container-xxl;
    margin-bottom: var(--article-margin);

    &:first-child {
        margin-top: var(--header-offset-y);
    }

    &:last-child {
        margin-bottom: var(--container-margin-bottom);
    }
}

body.page-bg-triangle {
    background-color: #5d9ce8;
    --bg-triangle-size: min(60vh, 70vw);
    --bg-offset-top: min(40vh, 15vw);
    --bg-offset-top-min: clamp(100px, 10vw, 400px);

    .layout-footer {
        background-color: transparent;
    }

    .layout-wrapper::before {
        content: '';
        position: absolute;
        top: calc(var(--bg-offset-top) + var(--bg-offset-top-min));
        background-color: $secondary;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        clip-path: polygon(0 var(--bg-triangle-size), 100% 0, 100% 100%, 0% 100%);
    }

    .layout-container {
        @include padding-bottom(70px);
    }
}
