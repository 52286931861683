.social-links {
    font-size: 45px;
    line-height: 1;

    &__list {
        --social-links-gap: 0.6em;
        @include list-unstyled;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        @include make-row-col(var(--social-links-gap));

        li {
            a {
                @include link-variant($primary, $primary-light);
            }
        }
    }
}
