/* Page menu */
body.menu-active {
    --header-clip-top: 0;
    --header-clip-bottom: 1;
    overflow: hidden;
    transition: all 0.1s;
}

/* Menu content */
.header-content {
    padding-top: var(--header-padding-y);
    padding-bottom: calc(var(--container-padding-x) / 2);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);
    pointer-events: none;
    background-color: rgba($primary, .99);
    opacity: 0;
    visibility: hidden;
    color: $white;

    html.js & {
        @include transition(opacity $transition-time, transform $transition-time, visibility $transition-time);
    }

    body.menu-active & {
        pointer-events: auto;
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }

    > * {
        padding-right: calc(var(--container-padding-x) / 2);
        padding-left: calc(var(--container-padding-x) / 2);
        width: 100%;
    }
}

.header-bottom {
    font-size: 11px;
    margin-top: auto;
    padding-top: 2rem;
    text-align: center;

    a {
        color: inherit;
    }

    .social-links__list {
        justify-content: center;
        margin-bottom: 1rem;

        a {
            @include link-variant(inherit, $secondary);
        }
    }

    @include media-breakpoint-up(md) {
        &__footer {
            display: flex;
            column-gap: 1.75rem;
            justify-content: center;
        }
    }
}

/* Menu Trigger */
$anim_time: 1s;

//OPEN
@keyframes topBar_open {
    0% {
        transform: translateY(pxToEm(0px)) rotate(0deg);
        width: pxToEm(60px);
    }
    10% {
        transform: translateY(pxToEm(-8px)) rotate(0deg);
        width: pxToEm(60px);
    }
    50% {
        transform: translateY(pxToEm(25px)) rotate(45deg);
        width: pxToEm(84px);
    }
    75% {
        transform: translateY(pxToEm(12px)) rotate(45deg);
        width: pxToEm(84px);
    }
    100% {
        transform: translateY(pxToEm(17px)) rotate(45deg);
        width: pxToEm(84px);
    }
}

@keyframes bottomBar_open {
    0% {
        transform: translateY(pxToEm(0px)) rotate(0deg);
        width: pxToEm(60px);
    }
    10% {
        transform: translateY(pxToEm(-8px)) rotate(0deg);
        width: pxToEm(60px);
    }
    60% {
        transform: translateY(pxToEm(0px)) rotate(-45deg);
        width: pxToEm(84px);
    }
    75% {
        transform: translateY(pxToEm(-10px)) rotate(-45deg);
        width: pxToEm(84px);
    }
    100% {
        transform: translateY(pxToEm(-8px)) rotate(-45deg);
        width: pxToEm(84px);
    }
}

@keyframes menuLabel_open {
    0% {
        transform: translateY(pxToEm(0px));
        opacity: 1;
    }
    25% {
        transform: translateY(pxToEm(-18px));
    }
    45% {
        transform: translateY(pxToEm(44px));
        opacity: 1;
    }
    48% {
        transform: translateY(pxToEm(50px));
        opacity: 0;
    }
    100% {
        transform: translateY(pxToEm(30px));
        opacity: 0;
    }
}

// CLOSE
@keyframes topBar_close {
    0% {
        transform: translateY(pxToEm(17px)) rotate(45deg);
        width: pxToEm(84px);
    }
    35% {
        transform: translateY(pxToEm(-8px)) rotate(-4deg);
        width: pxToEm(60px);
    }
    53% {
        transform: translateY(pxToEm(10px)) rotate(3deg);
        width: pxToEm(60px);
    }
    70% {
        transform: translateY(pxToEm(-6px)) rotate(0deg);
        width: pxToEm(60px);
    }
    100% {
        transform: translateY(pxToEm(-2px)) rotate(0deg);
        width: pxToEm(60px);
    }
}

@keyframes bottomBar_close {
    0% {
        transform: translateY(pxToEm(-8px)) rotate(-45deg);
        width: pxToEm(84px);
    }
    35% {
        transform: translateY(pxToEm(-18px)) rotate(6deg);
        width: pxToEm(60px);
    }
    53% {
        transform: translateY(pxToEm(0px)) rotate(-3deg);
        width: pxToEm(60px);
    }
    68% {
        transform: translateY(pxToEm(-7px)) rotate(0deg);
        width: pxToEm(60px);
    }
    100% {
        transform: translateY(pxToEm(0px)) rotate(0deg);
        width: pxToEm(60px);
    }
}

@keyframes menuLabel_close {
    0% {
        transform: translateY(pxToEm(30px));
        opacity: 0;
    }
    5% {
        transform: translateY(pxToEm(25px));
        opacity: 1;
    }
    25% {
        transform: translateY(pxToEm(-30px));
        opacity: 1;
    }
    37% {
        transform: translateY(pxToEm(-22px));
        opacity: 1;
    }
    45% {
        transform: translateY(pxToEm(-22px));
        opacity: 1;
    }
    58% {
        transform: translateY(pxToEm(8px)) rotate(-10deg);
        opacity: 1;
    }
    83% {
        transform: translateY(pxToEm(-6px)) rotate(0deg);
        opacity: 1;
    }
    100% {
        transform: translateY(pxToEm(0px));
        opacity: 1;
    }
}

.menu-btn {
    --header-color: #{$primary};
    $this: &;
    font-size: clamp(7px, 1.5vw, 14px);
    display: block;
    position: relative;
    text-align: center;
    width: pxToEm(60px);
    height: pxToEm(65px);
    z-index: 9;

    &:before,
    &:after {
        content: '';
        width: 100%;
        height: 0.57em;
        background-color: var(--header-color);
        display: block;
        position: absolute;
        border-radius: pxToEm(20px);

        body.menu-active & {
            --header-color: #{$white};
        }
    }

    &:before {
        transform-origin: left center;
    }

    &:after {
        right: 0;
        top: 1.785em;
        transform-origin: right center;
    }

    &__text {
        color: var(--color);
        font-family: 'Avenir Next', sans-serif;
        display: block;
        position: absolute;
        bottom: 0;
        font-size: 1em;
        letter-spacing: 0.3em;
        font-weight: $font-weight-bold;
    }

    &:hover {
        cursor: pointer;
    }

    body.menu-active & {
        &:before {
            animation: topBar_open $anim_time ease-in-out;
            animation-fill-mode: forwards;
        }

        &:after {
            animation: bottomBar_open $anim_time ease-in-out;
            animation-fill-mode: forwards;
        }

        #{$this}__text {
            animation: menuLabel_open $anim_time ease-in;
            animation-fill-mode: forwards;
        }
    }

    body.menu-close & {
        &:before {
            animation: topBar_close $anim_time ease-in-out;
            animation-fill-mode: forwards;
        }

        &:after {
            animation: bottomBar_close $anim_time ease-in-out;
            animation-fill-mode: forwards;
        }

        #{$this}__text {
            animation: menuLabel_close $anim_time ease-in;
            animation-fill-mode: forwards;
        }
    }
}


